@media screen and (max-width: 839px) {
  #map_modal[data-modal='poi'] .modal__container {
    padding-bottom: 0;
    width: calc(100vw - 40px);
    filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.6));

    & > #map_modal_content {
      width: calc(100vw - 100px);
      border-radius: 4px;
    }
  }
  .modal_button_group {
    top: unset;
    right: 15px;
    bottom: 1rem;
    margin: auto;

    button {
      padding: 10px;
    }
  }
}

@media screen and (min-width: 769px) {


  //#mobile_timeline {
  //  display: none;
  //}

  #mobile_nav {
    display: none;
  }
}

@media screen and (max-width: 768px) {

  //#timeline_content {
  //  display: none;
  //}

  #map_bg_menu li > span:last-child {
    display: none;
  }

  #map_container {
    height: calc(100vh - 160px) !important;
    min-height: 525px !important;
  }

  #map_container.wind div#wind_quick_legend {
    display: none;
  }

  div#timeline {
    flex-direction: column;
    min-height: 50px;

    #timeline_viewport {
      order: 1;
      flex: 0 0 44px;
      min-height: 44px;

      .tl_item {
        height: 40px;

        &[data-fc]:after {
          font-size: 12px;
          height: 20px;
          line-height: 20px;
        }

        &[data-day]:before {
          font-size: 10px;
          bottom: 24px;
        }
      }
    }

    #timeline_ts #timeline_cursor {
      height: 44px;
    }

    &.weather_details {
      #timeline_commands {
        flex: 1 0 auto;

        & > button {
          width: 32px;
          height: 32px;
        }
      }

      #timeline_viewport {
        flex: 0 0 auto;
        //padding-left: calc(100vw * (1 - 0.55) / (2 * 0.55));

        #weather_details {
          padding-top: 10px;
        }
      }

      #timeline_ts #timeline_cursor {
        height: 290px;
      }

      #timeline_viewport #timeline_content {
        padding-left: 0;
      }

      #modal_favorite > span {
        display: none;
      }
    }

    #timeline_ts {
      bottom: 64px;

      #timeline_date {
        left: 10px;
        top: 2px;
        font-size: 10px;
      }
    }

    #timeline_commands {
      flex: 0 0 auto;
      order: 2;
      margin: 0 32px;
      height: 40px;
      transition: height 0.8s ease;
    }

    &.no_focus {
      & + div#map_layers {
        bottom: 80px;
      }

      #timeline_commands {
        height: 0;
      }
    }

    #weather_details_header {
      order: -1;
      width: 110px;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 56px;
      background-color: white;
      z-index: 2;

      .heading_table {
        & > div {
          padding: 0 8px;
          font-size: .8em;
          //font-weight: 300;
          line-height: 1.1;

          span.short_label {
            display: inline;

            & + span {
              display: none;
            }
          }

          &.poi_title {
            padding: 0 8px;
          }
        }
      }
    }

    //#timeline_commands {
    //  left: calc(50vw - 140px);
    //  width: 264px;
    //
    //  #step_backward, #step_forward {
    //    display: initial;
    //  }
    //}
  }

  #map_modal {
    .radio-container {
      justify-content: start;

      &_item {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  div#map_layers {
    bottom: 120px;
    left: calc(50vw - 186px);
    transition: bottom 1s ease;
  }

  #map_menu span {
    display: none !important;
  }

  #footer-partner #footer-columns {
    flex-wrap: wrap;

    .img_logo img {
      height: 36px;
    }
  }

  .amp_modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .amp_photo{
      width: fit-content;
      padding: 1rem;
      img{
        max-width: 100%;
        height: auto;
      }
      p{
        font-size: 9px;
      }
    }

    .amp_description{
      width: fit-content;
      padding: 1rem;
    }
  }
}

@media screen and (max-width: 420px) {

  //#timeline_commands {
  //  #step_backward, #tl_step_forward {
  //    display: none;
  //  }
  //}

  #map_modal {
    .modal__header, #charts_actions, aside {
      flex-direction: column;
    }

    .radio-container {
      justify-content: start;

      &_item {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    #map_modal_content > aside > * {
      max-width: 100%;
    }

    .modal__header .partners_links img {
      height: 32px;
      margin-top: 5px;
    }
  }

  #map_wrapper {
    #map_container {
      .leaflet-top.leaflet-right {
        display: none;
      }

      .leaflet-bottom.leaflet-right {
        top: 20px;
        right: 10px;
      }

      #map_bg_list {
        top: 160px;
      }

      #amp_list{
        top: 72px;
      }

      #data_info {
        display: none;
        left: 20px;
        bottom: 105px;
        justify-content: center;
        width: 92%;

        & > p {
          br {
            display: none;
          }

          em {
            padding-left: 5px;
          }
        }
      }
    }
  }

  #news_modal {
    .modal__container {
      padding: 10px 10px 0 10px;
      max-width: calc(100vw - 20px);
    }

    #news_modal_content {
      button {
        height: 10px;
        width: 10px;
        margin: 10px 3px 0 3px;
      }
    }

    #news_slides {
      .tns-item {
        & > div {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  #partners a {
    text-align: center;

    img {
      max-width: 300px;
    }
  }
}
