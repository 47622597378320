.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa-solid, .fa-regular, .fa-brands, .fas, .far, .fab, .fal, .fat, .fad, .fass, .fasr, .fasl, .fast, .fasds, .fa-light, .fa-thin, .fa-duotone, .fa-sharp, .fa-sharp-duotone, .fa-sharp-solid, .fa-classic, .fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .far, .fal, .fat, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-classic {
  font-family: "Font Awesome 6 Pro";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad, .fa-classic.fa-duotone, .fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fasds, .fa-sharp-duotone {
  font-family: "Font Awesome 6 Sharp Duotone";
  font-weight: 900;
}

.fass, .fasr, .fasl, .fast, .fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass, .fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-00:before {
  content: "";
}

.fa-1:before {
  content: "1";
}

.fa-100:before {
  content: "";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-360-degrees:before {
  content: "";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-a:before {
  content: "A";
}

.fa-abacus:before {
  content: "";
}

.fa-accent-grave:before {
  content: "`";
}

.fa-acorn:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-add:before {
  content: "+";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airplay:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-album-circle-plus:before {
  content: "";
}

.fa-album-circle-user:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-album-collection-circle-plus:before {
  content: "";
}

.fa-album-collection-circle-user:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-alien-8bit:before, .fa-alien-monster:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-alt:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-ampersand:before {
  content: "&";
}

.fa-analytics:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-angle:before {
  content: "";
}

.fa-angle-90:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angles-down:before {
  content: "";
}

.fa-angles-left:before {
  content: "";
}

.fa-angles-right:before {
  content: "";
}

.fa-angles-up:before {
  content: "";
}

.fa-angles-up-down:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-ant:before {
  content: "";
}

.fa-apartment:before {
  content: "";
}

.fa-aperture:before {
  content: "";
}

.fa-apostrophe:before {
  content: "'";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-core:before {
  content: "";
}

.fa-apple-crate:before {
  content: "";
}

.fa-apple-whole:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-alt-down:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-arrow-alt-from-left:before {
  content: "";
}

.fa-arrow-alt-from-right:before {
  content: "";
}

.fa-arrow-alt-from-top:before {
  content: "";
}

.fa-arrow-alt-left:before {
  content: "";
}

.fa-arrow-alt-right:before {
  content: "";
}

.fa-arrow-alt-square-down:before {
  content: "";
}

.fa-arrow-alt-square-left:before {
  content: "";
}

.fa-arrow-alt-square-right:before {
  content: "";
}

.fa-arrow-alt-square-up:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-arrow-alt-to-left:before {
  content: "";
}

.fa-arrow-alt-to-right:before {
  content: "";
}

.fa-arrow-alt-to-top:before {
  content: "";
}

.fa-arrow-alt-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-down-1-9:before {
  content: "";
}

.fa-arrow-down-9-1:before {
  content: "";
}

.fa-arrow-down-a-z:before {
  content: "";
}

.fa-arrow-down-arrow-up:before {
  content: "";
}

.fa-arrow-down-big-small:before {
  content: "";
}

.fa-arrow-down-from-arc:before {
  content: "";
}

.fa-arrow-down-from-bracket:before {
  content: "";
}

.fa-arrow-down-from-dotted-line:before {
  content: "";
}

.fa-arrow-down-from-line:before {
  content: "";
}

.fa-arrow-down-left:before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "";
}

.fa-arrow-down-long:before {
  content: "";
}

.fa-arrow-down-right:before {
  content: "";
}

.fa-arrow-down-short-wide:before {
  content: "";
}

.fa-arrow-down-small-big:before {
  content: "";
}

.fa-arrow-down-square-triangle:before {
  content: "";
}

.fa-arrow-down-to-arc:before {
  content: "";
}

.fa-arrow-down-to-bracket:before {
  content: "";
}

.fa-arrow-down-to-dotted-line:before {
  content: "";
}

.fa-arrow-down-to-line:before {
  content: "";
}

.fa-arrow-down-to-square:before {
  content: "";
}

.fa-arrow-down-triangle-square:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-arrow-down-wide-short:before {
  content: "";
}

.fa-arrow-down-z-a:before {
  content: "";
}

.fa-arrow-from-bottom:before {
  content: "";
}

.fa-arrow-from-left:before {
  content: "";
}

.fa-arrow-from-right:before {
  content: "";
}

.fa-arrow-from-top:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-left-from-arc:before {
  content: "";
}

.fa-arrow-left-from-bracket:before {
  content: "";
}

.fa-arrow-left-from-line:before {
  content: "";
}

.fa-arrow-left-long:before {
  content: "";
}

.fa-arrow-left-long-to-line:before {
  content: "";
}

.fa-arrow-left-rotate:before {
  content: "";
}

.fa-arrow-left-to-arc:before {
  content: "";
}

.fa-arrow-left-to-bracket:before {
  content: "";
}

.fa-arrow-left-to-line:before {
  content: "";
}

.fa-arrow-pointer:before {
  content: "";
}

.fa-arrow-progress:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-right-arrow-left:before {
  content: "";
}

.fa-arrow-right-from-arc:before {
  content: "";
}

.fa-arrow-right-from-bracket:before {
  content: "";
}

.fa-arrow-right-from-file:before {
  content: "";
}

.fa-arrow-right-from-line:before {
  content: "";
}

.fa-arrow-right-long:before {
  content: "";
}

.fa-arrow-right-long-to-line:before {
  content: "";
}

.fa-arrow-right-rotate:before {
  content: "";
}

.fa-arrow-right-to-arc:before {
  content: "";
}

.fa-arrow-right-to-bracket:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-arrow-right-to-file:before {
  content: "";
}

.fa-arrow-right-to-line:before {
  content: "";
}

.fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before {
  content: "";
}

.fa-arrow-rotate-forward:before {
  content: "";
}

.fa-arrow-rotate-left:before {
  content: "";
}

.fa-arrow-rotate-right:before {
  content: "";
}

.fa-arrow-square-down:before {
  content: "";
}

.fa-arrow-square-left:before {
  content: "";
}

.fa-arrow-square-right:before {
  content: "";
}

.fa-arrow-square-up:before {
  content: "";
}

.fa-arrow-to-bottom:before {
  content: "";
}

.fa-arrow-to-left:before {
  content: "";
}

.fa-arrow-to-right:before {
  content: "";
}

.fa-arrow-to-top:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-arrow-turn-down:before {
  content: "";
}

.fa-arrow-turn-down-left:before {
  content: "";
}

.fa-arrow-turn-down-right:before {
  content: "";
}

.fa-arrow-turn-left:before {
  content: "";
}

.fa-arrow-turn-left-down:before {
  content: "";
}

.fa-arrow-turn-left-up:before {
  content: "";
}

.fa-arrow-turn-right:before {
  content: "";
}

.fa-arrow-turn-up:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-up-1-9:before {
  content: "";
}

.fa-arrow-up-9-1:before {
  content: "";
}

.fa-arrow-up-a-z:before {
  content: "";
}

.fa-arrow-up-arrow-down:before {
  content: "";
}

.fa-arrow-up-big-small:before {
  content: "";
}

.fa-arrow-up-from-arc:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-arrow-up-from-dotted-line:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-arrow-up-from-line:before {
  content: "";
}

.fa-arrow-up-from-square:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-arrow-up-left:before {
  content: "";
}

.fa-arrow-up-left-from-circle:before {
  content: "";
}

.fa-arrow-up-long:before {
  content: "";
}

.fa-arrow-up-right:before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-arrow-up-right-from-square:before {
  content: "";
}

.fa-arrow-up-short-wide:before {
  content: "";
}

.fa-arrow-up-small-big:before {
  content: "";
}

.fa-arrow-up-square-triangle:before {
  content: "";
}

.fa-arrow-up-to-arc:before {
  content: "";
}

.fa-arrow-up-to-bracket:before {
  content: "";
}

.fa-arrow-up-to-dotted-line:before {
  content: "";
}

.fa-arrow-up-to-line:before {
  content: "";
}

.fa-arrow-up-triangle-square:before {
  content: "";
}

.fa-arrow-up-wide-short:before {
  content: "";
}

.fa-arrow-up-z-a:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-arrows-cross:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-arrows-from-dotted-line:before {
  content: "";
}

.fa-arrows-from-line:before {
  content: "";
}

.fa-arrows-h:before, .fa-arrows-left-right:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-arrows-maximize:before {
  content: "";
}

.fa-arrows-minimize:before {
  content: "";
}

.fa-arrows-repeat:before {
  content: "";
}

.fa-arrows-repeat-1:before {
  content: "";
}

.fa-arrows-retweet:before {
  content: "";
}

.fa-arrows-rotate:before {
  content: "";
}

.fa-arrows-rotate-reverse:before {
  content: "";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-arrows-to-dotted-line:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-arrows-to-line:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-arrows-up-down:before {
  content: "";
}

.fa-arrows-up-down-left-right:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-asl-interpreting:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-at:before {
  content: "@";
}

.fa-atlas:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-atom-alt:before, .fa-atom-simple:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-audio-description-slash:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-automobile:before {
  content: "";
}

.fa-avocado:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-award-simple:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-backward-fast:before {
  content: "";
}

.fa-backward-step:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-badminton:before {
  content: "";
}

.fa-bag-seedling:before {
  content: "";
}

.fa-bag-shopping:before {
  content: "";
}

.fa-bag-shopping-minus:before {
  content: "";
}

.fa-bag-shopping-plus:before {
  content: "";
}

.fa-bagel:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-baguette:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-balloon:before {
  content: "";
}

.fa-balloons:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-ban-bug:before {
  content: "";
}

.fa-ban-parking:before {
  content: "";
}

.fa-ban-smoking:before {
  content: "";
}

.fa-banana:before {
  content: "";
}

.fa-band-aid:before, .fa-bandage:before {
  content: "";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-bank:before {
  content: "";
}

.fa-bar-chart:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-barcode-alt:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-barn-silo:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-bars-filter:before {
  content: "";
}

.fa-bars-progress:before {
  content: "";
}

.fa-bars-sort:before {
  content: "";
}

.fa-bars-staggered:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-basket-shopping:before {
  content: "";
}

.fa-basket-shopping-minus:before {
  content: "";
}

.fa-basket-shopping-plus:before {
  content: "";
}

.fa-basket-shopping-simple:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-battery:before {
  content: "";
}

.fa-battery-0:before {
  content: "";
}

.fa-battery-1:before {
  content: "";
}

.fa-battery-2:before {
  content: "";
}

.fa-battery-3:before {
  content: "";
}

.fa-battery-4:before {
  content: "";
}

.fa-battery-5:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-battery-car:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-exclamation:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-low:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-bed-alt:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-bed-front:before {
  content: "";
}

.fa-bed-pulse:before {
  content: "";
}

.fa-bee:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-beer-foam:before, .fa-beer-mug:before {
  content: "";
}

.fa-beer-mug-empty:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-concierge:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-bell-ring:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-bench-tree:before {
  content: "";
}

.fa-betamax:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-biking-mountain:before {
  content: "";
}

.fa-billboard:before {
  content: "";
}

.fa-bin-bottles:before {
  content: "";
}

.fa-bin-bottles-recycle:before {
  content: "";
}

.fa-bin-recycle:before {
  content: "";
}

.fa-binary:before {
  content: "";
}

.fa-binary-circle-check:before {
  content: "";
}

.fa-binary-lock:before {
  content: "";
}

.fa-binary-slash:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-bird:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-blackboard:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-blanket-fire:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-block:before {
  content: "";
}

.fa-block-brick:before {
  content: "";
}

.fa-block-brick-fire:before {
  content: "";
}

.fa-block-question:before {
  content: "";
}

.fa-block-quote:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blueberries:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bolt-auto:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-bolt-slash:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-alt:before {
  content: "";
}

.fa-book-arrow-right:before {
  content: "";
}

.fa-book-arrow-up:before {
  content: "";
}

.fa-book-atlas:before {
  content: "";
}

.fa-book-bible:before {
  content: "";
}

.fa-book-blank:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-book-circle:before {
  content: "";
}

.fa-book-circle-arrow-right:before {
  content: "";
}

.fa-book-circle-arrow-up:before {
  content: "";
}

.fa-book-copy:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-font:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-book-journal-whills:before {
  content: "";
}

.fa-book-law:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-open-alt:before, .fa-book-open-cover:before {
  content: "";
}

.fa-book-open-reader:before {
  content: "";
}

.fa-book-quran:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-book-section:before {
  content: "";
}

.fa-book-skull:before {
  content: "";
}

.fa-book-sparkles:before, .fa-book-spells:before {
  content: "";
}

.fa-book-tanakh:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bookmark-circle:before {
  content: "";
}

.fa-bookmark-slash:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-boot-heeled:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-border-bottom-right:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-border-style-alt:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-border-top-left:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-bottle-baby:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-bowl-chopsticks:before {
  content: "";
}

.fa-bowl-chopsticks-noodles:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-bowl-hot:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-bowl-salad:before {
  content: "";
}

.fa-bowl-scoop:before {
  content: "";
}

.fa-bowl-scoops:before {
  content: "";
}

.fa-bowl-shaved-ice:before {
  content: "";
}

.fa-bowl-soft-serve:before {
  content: "";
}

.fa-bowl-spoon:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-bowling-ball-pin:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-alt:before {
  content: "";
}

.fa-box-archive:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-box-circle-check:before {
  content: "";
}

.fa-box-dollar:before {
  content: "";
}

.fa-box-fragile:before {
  content: "";
}

.fa-box-full:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-open-full:before {
  content: "";
}

.fa-box-taped:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-box-up:before {
  content: "";
}

.fa-box-usd:before {
  content: "";
}

.fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-boxes-stacked:before {
  content: "";
}

.fa-boxing-glove:before {
  content: "";
}

.fa-bracket:before {
  content: "[";
}

.fa-bracket-curly:before, .fa-bracket-curly-left:before {
  content: "{";
}

.fa-bracket-curly-right:before {
  content: "}";
}

.fa-bracket-left:before {
  content: "[";
}

.fa-bracket-round:before {
  content: "(";
}

.fa-bracket-round-right:before {
  content: ")";
}

.fa-bracket-square:before {
  content: "[";
}

.fa-bracket-square-right:before {
  content: "]";
}

.fa-brackets:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-brackets-round:before {
  content: "";
}

.fa-brackets-square:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-brain-arrow-curved-right:before {
  content: "";
}

.fa-brain-circuit:before {
  content: "";
}

.fa-brake-warning:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-bread-slice-butter:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-bridge-suspension:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-arrow-right:before {
  content: "";
}

.fa-briefcase-blank:before {
  content: "";
}

.fa-briefcase-clock:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-brightness:before {
  content: "";
}

.fa-brightness-low:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broccoli:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-broom-ball:before {
  content: "";
}

.fa-broom-wide:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-browsers:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-building-columns:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-building-magnifying-glass:before {
  content: "";
}

.fa-building-memo:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-buildings:before {
  content: "";
}

.fa-bulldozer:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-buoy:before {
  content: "";
}

.fa-buoy-mooring:before {
  content: "";
}

.fa-burger:before {
  content: "";
}

.fa-burger-cheese:before {
  content: "";
}

.fa-burger-fries:before {
  content: "";
}

.fa-burger-glass:before {
  content: "";
}

.fa-burger-lettuce:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-bus-simple:before {
  content: "";
}

.fa-business-front:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-butter:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-cab:before {
  content: "";
}

.fa-cabin:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-cable-car:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-caduceus:before {
  content: "";
}

.fa-cake:before, .fa-cake-candles:before {
  content: "";
}

.fa-cake-slice:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calculator-alt:before, .fa-calculator-simple:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-arrow-down:before {
  content: "";
}

.fa-calendar-arrow-up:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-circle:before {
  content: "";
}

.fa-calendar-circle-exclamation:before {
  content: "";
}

.fa-calendar-circle-minus:before {
  content: "";
}

.fa-calendar-circle-plus:before {
  content: "";
}

.fa-calendar-circle-user:before {
  content: "";
}

.fa-calendar-clock:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-days:before {
  content: "";
}

.fa-calendar-download:before {
  content: "";
}

.fa-calendar-edit:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-calendar-heart:before {
  content: "";
}

.fa-calendar-image:before {
  content: "";
}

.fa-calendar-lines:before {
  content: "";
}

.fa-calendar-lines-pen:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-note:before {
  content: "";
}

.fa-calendar-pen:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-range:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-calendar-time:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-upload:before {
  content: "";
}

.fa-calendar-users:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-calendar-xmark:before {
  content: "";
}

.fa-calendars:before {
  content: "";
}

.fa-camcorder:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-camera-cctv:before {
  content: "";
}

.fa-camera-circle:before {
  content: "";
}

.fa-camera-home:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-camera-security:before {
  content: "";
}

.fa-camera-slash:before {
  content: "";
}

.fa-camera-viewfinder:before {
  content: "";
}

.fa-camera-web:before {
  content: "";
}

.fa-camera-web-slash:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-can-food:before {
  content: "";
}

.fa-cancel:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-candy:before {
  content: "";
}

.fa-candy-bar:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-cannon:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-bolt:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-car-burst:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-car-circle-bolt:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-car-mechanic:before {
  content: "";
}

.fa-car-mirrors:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-car-rear:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-car-side-bolt:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-car-wrench:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caravan-alt:before, .fa-caravan-simple:before {
  content: "";
}

.fa-card-club:before {
  content: "";
}

.fa-card-diamond:before {
  content: "";
}

.fa-card-heart:before {
  content: "";
}

.fa-card-spade:before {
  content: "";
}

.fa-cards:before {
  content: "";
}

.fa-cards-blank:before {
  content: "";
}

.fa-caret-circle-down:before {
  content: "";
}

.fa-caret-circle-left:before {
  content: "";
}

.fa-caret-circle-right:before {
  content: "";
}

.fa-caret-circle-up:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carriage-baby:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-arrow-up:before {
  content: "";
}

.fa-cart-circle-arrow-down:before {
  content: "";
}

.fa-cart-circle-arrow-up:before {
  content: "";
}

.fa-cart-circle-check:before {
  content: "";
}

.fa-cart-circle-exclamation:before {
  content: "";
}

.fa-cart-circle-plus:before {
  content: "";
}

.fa-cart-circle-xmark:before {
  content: "";
}

.fa-cart-flatbed:before {
  content: "";
}

.fa-cart-flatbed-boxes:before {
  content: "";
}

.fa-cart-flatbed-empty:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before {
  content: "";
}

.fa-cart-minus:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-shopping:before {
  content: "";
}

.fa-cart-shopping-fast:before {
  content: "";
}

.fa-cart-xmark:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cassette-betamax:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-cassette-vhs:before {
  content: "";
}

.fa-castle:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-cctv:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chain:before {
  content: "";
}

.fa-chain-broken:before {
  content: "";
}

.fa-chain-horizontal:before {
  content: "";
}

.fa-chain-horizontal-slash:before {
  content: "";
}

.fa-chain-slash:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before, .fa-chalkboard-user:before {
  content: "";
}

.fa-champagne-glass:before {
  content: "";
}

.fa-champagne-glasses:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-bullet:before {
  content: "";
}

.fa-chart-candlestick:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-chart-kanban:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-chart-line-up:before {
  content: "";
}

.fa-chart-line-up-down:before {
  content: "";
}

.fa-chart-mixed:before {
  content: "";
}

.fa-chart-mixed-up-circle-currency:before {
  content: "";
}

.fa-chart-mixed-up-circle-dollar:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-chart-pie-alt:before, .fa-chart-pie-simple:before {
  content: "";
}

.fa-chart-pie-simple-circle-currency:before {
  content: "";
}

.fa-chart-pie-simple-circle-dollar:before {
  content: "";
}

.fa-chart-pyramid:before {
  content: "";
}

.fa-chart-radar:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-chart-scatter-3d:before {
  content: "";
}

.fa-chart-scatter-bubble:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-chart-simple-horizontal:before {
  content: "";
}

.fa-chart-tree-map:before {
  content: "";
}

.fa-chart-user:before {
  content: "";
}

.fa-chart-waterfall:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-check-to-slot:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-cheeseburger:before {
  content: "";
}

.fa-cherries:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-bishop-alt:before, .fa-chess-bishop-piece:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-chess-clock-alt:before, .fa-chess-clock-flip:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-king-alt:before, .fa-chess-king-piece:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-knight-alt:before, .fa-chess-knight-piece:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-pawn-alt:before, .fa-chess-pawn-piece:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-queen-alt:before, .fa-chess-queen-piece:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chess-rook-alt:before, .fa-chess-rook-piece:before {
  content: "";
}

.fa-chestnut:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-double-down:before {
  content: "";
}

.fa-chevron-double-left:before {
  content: "";
}

.fa-chevron-double-right:before {
  content: "";
}

.fa-chevron-double-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-square-down:before {
  content: "";
}

.fa-chevron-square-left:before {
  content: "";
}

.fa-chevron-square-right:before {
  content: "";
}

.fa-chevron-square-up:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevrons-down:before {
  content: "";
}

.fa-chevrons-left:before {
  content: "";
}

.fa-chevrons-right:before {
  content: "";
}

.fa-chevrons-up:before {
  content: "";
}

.fa-chf-sign:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-child-combatant:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-child-rifle:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-chocolate-bar:before {
  content: "";
}

.fa-chopsticks:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-0:before {
  content: "";
}

.fa-circle-1:before {
  content: "";
}

.fa-circle-2:before {
  content: "";
}

.fa-circle-3:before {
  content: "";
}

.fa-circle-4:before {
  content: "";
}

.fa-circle-5:before {
  content: "";
}

.fa-circle-6:before {
  content: "";
}

.fa-circle-7:before {
  content: "";
}

.fa-circle-8:before {
  content: "";
}

.fa-circle-9:before {
  content: "";
}

.fa-circle-a:before {
  content: "";
}

.fa-circle-ampersand:before {
  content: "";
}

.fa-circle-arrow-down:before {
  content: "";
}

.fa-circle-arrow-down-left:before {
  content: "";
}

.fa-circle-arrow-down-right:before {
  content: "";
}

.fa-circle-arrow-left:before {
  content: "";
}

.fa-circle-arrow-right:before {
  content: "";
}

.fa-circle-arrow-up:before {
  content: "";
}

.fa-circle-arrow-up-left:before {
  content: "";
}

.fa-circle-arrow-up-right:before {
  content: "";
}

.fa-circle-b:before {
  content: "";
}

.fa-circle-bolt:before {
  content: "";
}

.fa-circle-book-open:before {
  content: "";
}

.fa-circle-bookmark:before {
  content: "";
}

.fa-circle-c:before {
  content: "";
}

.fa-circle-calendar:before {
  content: "";
}

.fa-circle-camera:before {
  content: "";
}

.fa-circle-caret-down:before {
  content: "";
}

.fa-circle-caret-left:before {
  content: "";
}

.fa-circle-caret-right:before {
  content: "";
}

.fa-circle-caret-up:before {
  content: "";
}

.fa-circle-check:before {
  content: "";
}

.fa-circle-chevron-down:before {
  content: "";
}

.fa-circle-chevron-left:before {
  content: "";
}

.fa-circle-chevron-right:before {
  content: "";
}

.fa-circle-chevron-up:before {
  content: "";
}

.fa-circle-d:before {
  content: "";
}

.fa-circle-dashed:before {
  content: "";
}

.fa-circle-divide:before {
  content: "";
}

.fa-circle-dollar:before {
  content: "";
}

.fa-circle-dollar-to-slot:before {
  content: "";
}

.fa-circle-dot:before {
  content: "";
}

.fa-circle-down:before {
  content: "";
}

.fa-circle-down-left:before {
  content: "";
}

.fa-circle-down-right:before {
  content: "";
}

.fa-circle-e:before {
  content: "";
}

.fa-circle-ellipsis:before {
  content: "";
}

.fa-circle-ellipsis-vertical:before {
  content: "";
}

.fa-circle-envelope:before {
  content: "";
}

.fa-circle-euro:before {
  content: "";
}

.fa-circle-exclamation:before {
  content: "";
}

.fa-circle-exclamation-check:before {
  content: "";
}

.fa-circle-f:before {
  content: "";
}

.fa-circle-g:before {
  content: "";
}

.fa-circle-gf:before {
  content: "";
}

.fa-circle-h:before {
  content: "";
}

.fa-circle-half:before {
  content: "";
}

.fa-circle-half-stroke:before {
  content: "";
}

.fa-circle-heart:before {
  content: "";
}

.fa-circle-i:before {
  content: "";
}

.fa-circle-info:before {
  content: "";
}

.fa-circle-j:before {
  content: "";
}

.fa-circle-k:before {
  content: "";
}

.fa-circle-l:before {
  content: "";
}

.fa-circle-left:before {
  content: "";
}

.fa-circle-location-arrow:before {
  content: "";
}

.fa-circle-m:before {
  content: "";
}

.fa-circle-microphone:before {
  content: "";
}

.fa-circle-microphone-lines:before {
  content: "";
}

.fa-circle-minus:before {
  content: "";
}

.fa-circle-n:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-circle-p:before {
  content: "";
}

.fa-circle-parking:before {
  content: "";
}

.fa-circle-pause:before {
  content: "";
}

.fa-circle-phone:before {
  content: "";
}

.fa-circle-phone-flip:before {
  content: "";
}

.fa-circle-phone-hangup:before {
  content: "";
}

.fa-circle-play:before {
  content: "";
}

.fa-circle-plus:before {
  content: "";
}

.fa-circle-q:before {
  content: "";
}

.fa-circle-quarter:before {
  content: "";
}

.fa-circle-quarter-stroke:before {
  content: "";
}

.fa-circle-quarters:before {
  content: "";
}

.fa-circle-question:before {
  content: "";
}

.fa-circle-r:before {
  content: "";
}

.fa-circle-radiation:before {
  content: "";
}

.fa-circle-right:before {
  content: "";
}

.fa-circle-s:before {
  content: "";
}

.fa-circle-small:before {
  content: "";
}

.fa-circle-sort:before {
  content: "";
}

.fa-circle-sort-down:before {
  content: "";
}

.fa-circle-sort-up:before {
  content: "";
}

.fa-circle-star:before {
  content: "";
}

.fa-circle-sterling:before {
  content: "";
}

.fa-circle-stop:before {
  content: "";
}

.fa-circle-t:before {
  content: "";
}

.fa-circle-three-quarters:before {
  content: "";
}

.fa-circle-three-quarters-stroke:before {
  content: "";
}

.fa-circle-trash:before {
  content: "";
}

.fa-circle-u:before {
  content: "";
}

.fa-circle-up:before {
  content: "";
}

.fa-circle-up-left:before {
  content: "";
}

.fa-circle-up-right:before {
  content: "";
}

.fa-circle-user:before {
  content: "";
}

.fa-circle-v:before {
  content: "";
}

.fa-circle-video:before {
  content: "";
}

.fa-circle-w:before {
  content: "";
}

.fa-circle-waveform-lines:before {
  content: "";
}

.fa-circle-wifi:before {
  content: "";
}

.fa-circle-wifi-circle-wifi:before, .fa-circle-wifi-group:before {
  content: "";
}

.fa-circle-x:before {
  content: "";
}

.fa-circle-xmark:before {
  content: "";
}

.fa-circle-y:before {
  content: "";
}

.fa-circle-yen:before {
  content: "";
}

.fa-circle-z:before {
  content: "";
}

.fa-circles-overlap:before {
  content: "";
}

.fa-citrus:before {
  content: "";
}

.fa-citrus-slice:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-clapperboard-play:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-clipboard-medical:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clock-desk:before {
  content: "";
}

.fa-clock-eight:before {
  content: "";
}

.fa-clock-eight-thirty:before {
  content: "";
}

.fa-clock-eleven:before {
  content: "";
}

.fa-clock-eleven-thirty:before {
  content: "";
}

.fa-clock-five:before {
  content: "";
}

.fa-clock-five-thirty:before {
  content: "";
}

.fa-clock-four:before {
  content: "";
}

.fa-clock-four-thirty:before {
  content: "";
}

.fa-clock-nine:before {
  content: "";
}

.fa-clock-nine-thirty:before {
  content: "";
}

.fa-clock-one:before {
  content: "";
}

.fa-clock-one-thirty:before {
  content: "";
}

.fa-clock-rotate-left:before {
  content: "";
}

.fa-clock-seven:before {
  content: "";
}

.fa-clock-seven-thirty:before {
  content: "";
}

.fa-clock-six:before {
  content: "";
}

.fa-clock-six-thirty:before {
  content: "";
}

.fa-clock-ten:before {
  content: "";
}

.fa-clock-ten-thirty:before {
  content: "";
}

.fa-clock-three:before {
  content: "";
}

.fa-clock-three-thirty:before {
  content: "";
}

.fa-clock-twelve:before {
  content: "";
}

.fa-clock-twelve-thirty:before {
  content: "";
}

.fa-clock-two:before {
  content: "";
}

.fa-clock-two-thirty:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-close:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-closed-captioning-slash:before {
  content: "";
}

.fa-clothes-hanger:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-arrow-down:before {
  content: "";
}

.fa-cloud-arrow-up:before {
  content: "";
}

.fa-cloud-binary:before {
  content: "";
}

.fa-cloud-bolt:before {
  content: "";
}

.fa-cloud-bolt-moon:before {
  content: "";
}

.fa-cloud-bolt-sun:before {
  content: "";
}

.fa-cloud-check:before {
  content: "";
}

.fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-cloud-exclamation:before {
  content: "";
}

.fa-cloud-fog:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-minus:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-cloud-plus:before {
  content: "";
}

.fa-cloud-question:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-cloud-slash:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloud-word:before {
  content: "";
}

.fa-cloud-xmark:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-cny:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-coconut:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-code-pull-request-closed:before {
  content: "";
}

.fa-code-pull-request-draft:before {
  content: "";
}

.fa-code-simple:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-coffee-bean:before {
  content: "";
}

.fa-coffee-beans:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-coffee-togo:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-coin-blank:before {
  content: "";
}

.fa-coin-front:before {
  content: "";
}

.fa-coin-vertical:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-colon:before {
  content: ":";
}

.fa-colon-sign:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-columns-3:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-comma:before {
  content: ",";
}

.fa-command:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-alt-arrow-down:before {
  content: "";
}

.fa-comment-alt-arrow-up:before {
  content: "";
}

.fa-comment-alt-captions:before {
  content: "";
}

.fa-comment-alt-check:before {
  content: "";
}

.fa-comment-alt-dollar:before {
  content: "";
}

.fa-comment-alt-dots:before {
  content: "";
}

.fa-comment-alt-edit:before {
  content: "";
}

.fa-comment-alt-exclamation:before {
  content: "";
}

.fa-comment-alt-image:before {
  content: "";
}

.fa-comment-alt-lines:before {
  content: "";
}

.fa-comment-alt-medical:before {
  content: "";
}

.fa-comment-alt-minus:before {
  content: "";
}

.fa-comment-alt-music:before {
  content: "";
}

.fa-comment-alt-plus:before {
  content: "";
}

.fa-comment-alt-quote:before {
  content: "";
}

.fa-comment-alt-slash:before {
  content: "";
}

.fa-comment-alt-smile:before {
  content: "";
}

.fa-comment-alt-text:before {
  content: "";
}

.fa-comment-alt-times:before {
  content: "";
}

.fa-comment-arrow-down:before {
  content: "";
}

.fa-comment-arrow-up:before {
  content: "";
}

.fa-comment-arrow-up-right:before {
  content: "";
}

.fa-comment-captions:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-comment-code:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-edit:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-comment-heart:before {
  content: "";
}

.fa-comment-image:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-middle:before {
  content: "";
}

.fa-comment-middle-alt:before {
  content: "";
}

.fa-comment-middle-top:before {
  content: "";
}

.fa-comment-middle-top-alt:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-comment-pen:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-comment-question:before {
  content: "";
}

.fa-comment-quote:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-comment-sms:before {
  content: "";
}

.fa-comment-text:before {
  content: "";
}

.fa-comment-times:before, .fa-comment-xmark:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-comments-alt-dollar:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-comments-question:before {
  content: "";
}

.fa-comments-question-check:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compass-drafting:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-computer-mouse:before {
  content: "";
}

.fa-computer-mouse-scrollwheel:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-construction:before {
  content: "";
}

.fa-contact-book:before {
  content: "";
}

.fa-contact-card:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-conveyor-belt-alt:before {
  content: "";
}

.fa-conveyor-belt-arm:before {
  content: "";
}

.fa-conveyor-belt-boxes:before {
  content: "";
}

.fa-conveyor-belt-empty:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-corner:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-couch-small:before {
  content: "";
}

.fa-court-sport:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-cowbell-circle-plus:before, .fa-cowbell-more:before {
  content: "";
}

.fa-crab:before {
  content: "";
}

.fa-crate-apple:before {
  content: "";
}

.fa-crate-empty:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-creemee:before {
  content: "";
}

.fa-cricket:before, .fa-cricket-bat-ball:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before, .fa-crop-simple:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crosshairs-simple:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-crystal-ball:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-cucumber:before {
  content: "";
}

.fa-cup-straw:before {
  content: "";
}

.fa-cup-straw-swoosh:before {
  content: "";
}

.fa-cup-togo:before {
  content: "";
}

.fa-cupcake:before {
  content: "";
}

.fa-curling:before, .fa-curling-stone:before {
  content: "";
}

.fa-custard:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-dagger:before {
  content: "";
}

.fa-dash:before {
  content: "";
}

.fa-dashboard:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before, .fa-deafness:before {
  content: "";
}

.fa-debug:before {
  content: "";
}

.fa-dedent:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-delete-left:before {
  content: "";
}

.fa-delete-right:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-desktop-arrow-down:before {
  content: "";
}

.fa-desktop-code:before {
  content: "";
}

.fa-desktop-medical:before {
  content: "";
}

.fa-desktop-slash:before {
  content: "";
}

.fa-dewpoint:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diagram-cells:before {
  content: "";
}

.fa-diagram-lean-canvas:before {
  content: "";
}

.fa-diagram-nested:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-diagram-previous:before {
  content: "";
}

.fa-diagram-project:before {
  content: "";
}

.fa-diagram-sankey:before {
  content: "";
}

.fa-diagram-subtask:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-diagram-venn:before {
  content: "";
}

.fa-dial:before {
  content: "";
}

.fa-dial-high:before {
  content: "";
}

.fa-dial-low:before {
  content: "";
}

.fa-dial-max:before {
  content: "";
}

.fa-dial-med:before {
  content: "";
}

.fa-dial-med-high:before {
  content: "";
}

.fa-dial-med-low:before {
  content: "";
}

.fa-dial-min:before {
  content: "";
}

.fa-dial-off:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-diamond-exclamation:before {
  content: "";
}

.fa-diamond-half:before {
  content: "";
}

.fa-diamond-half-stroke:before {
  content: "";
}

.fa-diamond-turn-right:before {
  content: "";
}

.fa-diamonds-4:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digging:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-dinosaur:before {
  content: "";
}

.fa-diploma:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-display-arrow-down:before {
  content: "";
}

.fa-display-chart-up:before {
  content: "";
}

.fa-display-chart-up-circle-currency:before {
  content: "";
}

.fa-display-chart-up-circle-dollar:before {
  content: "";
}

.fa-display-code:before {
  content: "";
}

.fa-display-medical:before {
  content: "";
}

.fa-display-slash:before {
  content: "";
}

.fa-distribute-spacing-horizontal:before {
  content: "";
}

.fa-distribute-spacing-vertical:before {
  content: "";
}

.fa-ditto:before {
  content: "\"";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-dollar:before {
  content: "$";
}

.fa-dollar-circle:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "$";
}

.fa-dollar-square:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-dolphin:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-donut:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-doughnut:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-down:before {
  content: "";
}

.fa-down-from-bracket:before {
  content: "";
}

.fa-down-from-dotted-line:before {
  content: "";
}

.fa-down-from-line:before {
  content: "";
}

.fa-down-left:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before {
  content: "";
}

.fa-down-long:before {
  content: "";
}

.fa-down-right:before {
  content: "";
}

.fa-down-to-bracket:before {
  content: "";
}

.fa-down-to-dotted-line:before {
  content: "";
}

.fa-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-drivers-license:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-drone-alt:before, .fa-drone-front:before {
  content: "";
}

.fa-droplet:before {
  content: "";
}

.fa-droplet-degree:before {
  content: "";
}

.fa-droplet-percent:before {
  content: "";
}

.fa-droplet-slash:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-dryer-alt:before, .fa-dryer-heat:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-ear:before {
  content: "";
}

.fa-ear-deaf:before {
  content: "";
}

.fa-ear-listen:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-earth:before {
  content: "";
}

.fa-earth-africa:before {
  content: "";
}

.fa-earth-america:before, .fa-earth-americas:before {
  content: "";
}

.fa-earth-asia:before {
  content: "";
}

.fa-earth-europe:before {
  content: "";
}

.fa-earth-oceania:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-eclipse-alt:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-eggplant:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-h-alt:before, .fa-ellipsis-stroke:before {
  content: "";
}

.fa-ellipsis-stroke-vertical:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ellipsis-v-alt:before {
  content: "";
}

.fa-ellipsis-vertical:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-engine:before {
  content: "";
}

.fa-engine-exclamation:before, .fa-engine-warning:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-badge:before {
  content: "";
}

.fa-envelope-circle:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-envelope-dot:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envelopes:before {
  content: "";
}

.fa-envelopes-bulk:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-eraser:before {
  content: "";
}

.fa-escalator:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-eur:before, .fa-euro:before, .fa-euro-sign:before {
  content: "";
}

.fa-excavator:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-square:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-exploding-head:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before {
  content: "";
}

.fa-eye-dropper-full:before {
  content: "";
}

.fa-eye-dropper-half:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-eye-low-vision:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-eyes:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-face-angry:before {
  content: "";
}

.fa-face-angry-horns:before {
  content: "";
}

.fa-face-anguished:before {
  content: "";
}

.fa-face-anxious-sweat:before {
  content: "";
}

.fa-face-astonished:before {
  content: "";
}

.fa-face-awesome:before {
  content: "";
}

.fa-face-beam-hand-over-mouth:before {
  content: "";
}

.fa-face-clouds:before {
  content: "";
}

.fa-face-confounded:before {
  content: "";
}

.fa-face-confused:before {
  content: "";
}

.fa-face-cowboy-hat:before {
  content: "";
}

.fa-face-diagonal-mouth:before {
  content: "";
}

.fa-face-disappointed:before {
  content: "";
}

.fa-face-disguise:before {
  content: "";
}

.fa-face-dizzy:before {
  content: "";
}

.fa-face-dotted:before {
  content: "";
}

.fa-face-downcast-sweat:before {
  content: "";
}

.fa-face-drooling:before {
  content: "";
}

.fa-face-exhaling:before {
  content: "";
}

.fa-face-explode:before {
  content: "";
}

.fa-face-expressionless:before {
  content: "";
}

.fa-face-eyes-xmarks:before {
  content: "";
}

.fa-face-fearful:before {
  content: "";
}

.fa-face-flushed:before {
  content: "";
}

.fa-face-frown:before {
  content: "";
}

.fa-face-frown-open:before {
  content: "";
}

.fa-face-frown-slight:before {
  content: "";
}

.fa-face-glasses:before {
  content: "";
}

.fa-face-grimace:before {
  content: "";
}

.fa-face-grin:before {
  content: "";
}

.fa-face-grin-beam:before {
  content: "";
}

.fa-face-grin-beam-sweat:before {
  content: "";
}

.fa-face-grin-hearts:before {
  content: "";
}

.fa-face-grin-squint:before {
  content: "";
}

.fa-face-grin-squint-tears:before {
  content: "";
}

.fa-face-grin-stars:before {
  content: "";
}

.fa-face-grin-tears:before {
  content: "";
}

.fa-face-grin-tongue:before {
  content: "";
}

.fa-face-grin-tongue-squint:before {
  content: "";
}

.fa-face-grin-tongue-wink:before {
  content: "";
}

.fa-face-grin-wide:before {
  content: "";
}

.fa-face-grin-wink:before {
  content: "";
}

.fa-face-hand-over-mouth:before {
  content: "";
}

.fa-face-hand-peeking:before {
  content: "";
}

.fa-face-hand-yawn:before {
  content: "";
}

.fa-face-head-bandage:before {
  content: "";
}

.fa-face-holding-back-tears:before {
  content: "";
}

.fa-face-hushed:before {
  content: "";
}

.fa-face-icicles:before {
  content: "";
}

.fa-face-kiss:before {
  content: "";
}

.fa-face-kiss-beam:before {
  content: "";
}

.fa-face-kiss-closed-eyes:before {
  content: "";
}

.fa-face-kiss-wink-heart:before {
  content: "";
}

.fa-face-laugh:before {
  content: "";
}

.fa-face-laugh-beam:before {
  content: "";
}

.fa-face-laugh-squint:before {
  content: "";
}

.fa-face-laugh-wink:before {
  content: "";
}

.fa-face-lying:before {
  content: "";
}

.fa-face-mask:before {
  content: "";
}

.fa-face-meh:before {
  content: "";
}

.fa-face-meh-blank:before {
  content: "";
}

.fa-face-melting:before {
  content: "";
}

.fa-face-monocle:before {
  content: "";
}

.fa-face-nauseated:before {
  content: "";
}

.fa-face-nose-steam:before {
  content: "";
}

.fa-face-party:before {
  content: "";
}

.fa-face-pensive:before {
  content: "";
}

.fa-face-persevering:before {
  content: "";
}

.fa-face-pleading:before {
  content: "";
}

.fa-face-pouting:before {
  content: "";
}

.fa-face-raised-eyebrow:before {
  content: "";
}

.fa-face-relieved:before {
  content: "";
}

.fa-face-rolling-eyes:before {
  content: "";
}

.fa-face-sad-cry:before {
  content: "";
}

.fa-face-sad-sweat:before {
  content: "";
}

.fa-face-sad-tear:before {
  content: "";
}

.fa-face-saluting:before {
  content: "";
}

.fa-face-scream:before {
  content: "";
}

.fa-face-shush:before {
  content: "";
}

.fa-face-sleeping:before {
  content: "";
}

.fa-face-sleepy:before {
  content: "";
}

.fa-face-smile:before {
  content: "";
}

.fa-face-smile-beam:before {
  content: "";
}

.fa-face-smile-halo:before {
  content: "";
}

.fa-face-smile-hearts:before {
  content: "";
}

.fa-face-smile-horns:before {
  content: "";
}

.fa-face-smile-plus:before {
  content: "";
}

.fa-face-smile-relaxed:before {
  content: "";
}

.fa-face-smile-tear:before {
  content: "";
}

.fa-face-smile-tongue:before {
  content: "";
}

.fa-face-smile-upside-down:before {
  content: "";
}

.fa-face-smile-wink:before {
  content: "";
}

.fa-face-smiling-hands:before {
  content: "";
}

.fa-face-smirking:before {
  content: "";
}

.fa-face-spiral-eyes:before {
  content: "";
}

.fa-face-sunglasses:before {
  content: "";
}

.fa-face-surprise:before {
  content: "";
}

.fa-face-swear:before {
  content: "";
}

.fa-face-thermometer:before {
  content: "";
}

.fa-face-thinking:before {
  content: "";
}

.fa-face-tired:before {
  content: "";
}

.fa-face-tissue:before {
  content: "";
}

.fa-face-tongue-money:before {
  content: "";
}

.fa-face-tongue-sweat:before {
  content: "";
}

.fa-face-unamused:before {
  content: "";
}

.fa-face-viewfinder:before {
  content: "";
}

.fa-face-vomit:before {
  content: "";
}

.fa-face-weary:before {
  content: "";
}

.fa-face-woozy:before {
  content: "";
}

.fa-face-worried:before {
  content: "";
}

.fa-face-zany:before {
  content: "";
}

.fa-face-zipper:before {
  content: "";
}

.fa-falafel:before {
  content: "";
}

.fa-family:before {
  content: "";
}

.fa-family-dress:before {
  content: "";
}

.fa-family-pants:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-farm:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before, .fa-feather-pointed:before {
  content: "";
}

.fa-feed:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fence:before {
  content: "";
}

.fa-ferris-wheel:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-field-hockey:before, .fa-field-hockey-stick-ball:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-arrow-down:before {
  content: "";
}

.fa-file-arrow-up:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-award:before {
  content: "";
}

.fa-file-binary:before {
  content: "";
}

.fa-file-cad:before {
  content: "";
}

.fa-file-caret-down:before {
  content: "";
}

.fa-file-caret-up:before {
  content: "";
}

.fa-file-certificate:before {
  content: "";
}

.fa-file-chart-column:before, .fa-file-chart-line:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-file-circle-info:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-file-clipboard:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-dashed-line:before {
  content: "";
}

.fa-file-doc:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-edit:before {
  content: "";
}

.fa-file-eps:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-gif:before {
  content: "";
}

.fa-file-heart:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-jpg:before {
  content: "";
}

.fa-file-lines:before {
  content: "";
}

.fa-file-lock:before {
  content: "";
}

.fa-file-magnifying-glass:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-file-mov:before {
  content: "";
}

.fa-file-mp3:before {
  content: "";
}

.fa-file-mp4:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-pen:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-file-plus-minus:before {
  content: "";
}

.fa-file-png:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-ppt:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-search:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-slash:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-file-svg:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-file-times:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-file-vector:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-waveform:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-xls:before {
  content: "";
}

.fa-file-xmark:before {
  content: "";
}

.fa-file-xml:before {
  content: "";
}

.fa-file-zip:before {
  content: "";
}

.fa-file-zipper:before {
  content: "";
}

.fa-files:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-film-alt:before {
  content: "";
}

.fa-film-canister:before, .fa-film-cannister:before {
  content: "";
}

.fa-film-simple:before {
  content: "";
}

.fa-film-slash:before {
  content: "";
}

.fa-films:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-filter-circle-dollar:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-filter-list:before {
  content: "";
}

.fa-filter-slash:before {
  content: "";
}

.fa-filters:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-fire-flame:before {
  content: "";
}

.fa-fire-flame-curved:before {
  content: "";
}

.fa-fire-flame-simple:before {
  content: "";
}

.fa-fire-hydrant:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-firewall:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fish-bones:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-fishing-rod:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-alt:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-pennant:before {
  content: "";
}

.fa-flag-swallowtail:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flame:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flask-gear:before {
  content: "";
}

.fa-flask-poison:before {
  content: "";
}

.fa-flask-potion:before {
  content: "";
}

.fa-flask-round-poison:before {
  content: "";
}

.fa-flask-round-potion:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-flatbread:before {
  content: "";
}

.fa-flatbread-stuffed:before {
  content: "";
}

.fa-floppy-disk:before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right:before {
  content: "";
}

.fa-floppy-disk-circle-xmark:before {
  content: "";
}

.fa-floppy-disk-pen:before {
  content: "";
}

.fa-floppy-disk-times:before {
  content: "";
}

.fa-floppy-disks:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-flying-disc:before {
  content: "";
}

.fa-fog:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-arrow-down:before {
  content: "";
}

.fa-folder-arrow-up:before {
  content: "";
}

.fa-folder-blank:before {
  content: "";
}

.fa-folder-bookmark:before {
  content: "";
}

.fa-folder-check:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-folder-cog:before {
  content: "";
}

.fa-folder-download:before {
  content: "";
}

.fa-folder-gear:before {
  content: "";
}

.fa-folder-grid:before {
  content: "";
}

.fa-folder-heart:before {
  content: "";
}

.fa-folder-image:before {
  content: "";
}

.fa-folder-magnifying-glass:before {
  content: "";
}

.fa-folder-medical:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-music:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-folder-search:before {
  content: "";
}

.fa-folder-times:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-folder-upload:before {
  content: "";
}

.fa-folder-user:before {
  content: "";
}

.fa-folder-xmark:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-fondue-pot:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-fork:before {
  content: "";
}

.fa-fork-knife:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-fort:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-forward-fast:before {
  content: "";
}

.fa-forward-step:before {
  content: "";
}

.fa-fragile:before {
  content: "";
}

.fa-frame:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frosty-head:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before {
  content: "";
}

.fa-g:before {
  content: "G";
}

.fa-galaxy:before {
  content: "";
}

.fa-gallery-thumbnails:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-game-board-alt:before, .fa-game-board-simple:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-game-console-handheld-crank:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gamepad-alt:before, .fa-gamepad-modern:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-garlic:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-gauge:before {
  content: "";
}

.fa-gauge-circle-bolt:before {
  content: "";
}

.fa-gauge-circle-minus:before {
  content: "";
}

.fa-gauge-circle-plus:before {
  content: "";
}

.fa-gauge-high:before {
  content: "";
}

.fa-gauge-low:before {
  content: "";
}

.fa-gauge-max:before {
  content: "";
}

.fa-gauge-med:before {
  content: "";
}

.fa-gauge-min:before {
  content: "";
}

.fa-gauge-simple:before {
  content: "";
}

.fa-gauge-simple-high:before {
  content: "";
}

.fa-gauge-simple-low:before {
  content: "";
}

.fa-gauge-simple-max:before {
  content: "";
}

.fa-gauge-simple-med:before {
  content: "";
}

.fa-gauge-simple-min:before {
  content: "";
}

.fa-gave-dandy:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-gear:before {
  content: "";
}

.fa-gear-code:before {
  content: "";
}

.fa-gear-complex:before {
  content: "";
}

.fa-gear-complex-code:before {
  content: "";
}

.fa-gears:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gif:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-glass-champagne:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-glass-empty:before {
  content: "";
}

.fa-glass-half:before, .fa-glass-half-empty:before, .fa-glass-half-full:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glasses-alt:before, .fa-glasses-round:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-globe-oceania:before {
  content: "";
}

.fa-globe-pointer:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-globe-wifi:before {
  content: "";
}

.fa-glove-boxing:before {
  content: "";
}

.fa-goal-net:before {
  content: "";
}

.fa-golf-ball:before, .fa-golf-ball-tee:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-golf-flag-hole:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-grapes:before {
  content: "";
}

.fa-grate:before {
  content: "";
}

.fa-grate-droplet:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grid:before {
  content: "";
}

.fa-grid-2:before {
  content: "";
}

.fa-grid-2-plus:before {
  content: "";
}

.fa-grid-3:before {
  content: "";
}

.fa-grid-4:before {
  content: "";
}

.fa-grid-5:before {
  content: "";
}

.fa-grid-dividers:before {
  content: "";
}

.fa-grid-horizontal:before {
  content: "";
}

.fa-grid-round:before {
  content: "";
}

.fa-grid-round-2:before {
  content: "";
}

.fa-grid-round-2-plus:before {
  content: "";
}

.fa-grid-round-4:before {
  content: "";
}

.fa-grid-round-5:before {
  content: "";
}

.fa-grill:before {
  content: "";
}

.fa-grill-fire:before {
  content: "";
}

.fa-grill-hot:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip:before {
  content: "";
}

.fa-grip-dots:before {
  content: "";
}

.fa-grip-dots-vertical:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-gun-slash:before {
  content: "";
}

.fa-gun-squirt:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-h-square:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-h5:before {
  content: "";
}

.fa-h6:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hammer-brush:before {
  content: "";
}

.fa-hammer-crash:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand:before {
  content: "";
}

.fa-hand-back-fist:before {
  content: "";
}

.fa-hand-back-point-down:before {
  content: "";
}

.fa-hand-back-point-left:before {
  content: "";
}

.fa-hand-back-point-ribbon:before {
  content: "";
}

.fa-hand-back-point-right:before {
  content: "";
}

.fa-hand-back-point-up:before {
  content: "";
}

.fa-hand-dots:before {
  content: "";
}

.fa-hand-fingers-crossed:before {
  content: "";
}

.fa-hand-fist:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-hand-holding-circle-dollar:before {
  content: "";
}

.fa-hand-holding-dollar:before {
  content: "";
}

.fa-hand-holding-droplet:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-hand-holding-skull:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-horns:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-love:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-ribbon:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-receiving:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hand-wave:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-american-sign-language-interpreting:before, .fa-hands-asl-interpreting:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-hands-bubbles:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-hands-heart:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-hands-holding-diamond:before {
  content: "";
}

.fa-hands-holding-dollar:before {
  content: "";
}

.fa-hands-holding-heart:before {
  content: "";
}

.fa-hands-praying:before {
  content: "";
}

.fa-hands-usd:before {
  content: "";
}

.fa-hands-wash:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "";
}

.fa-handshake-angle:before {
  content: "";
}

.fa-handshake-simple:before {
  content: "";
}

.fa-handshake-simple-slash:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-drive:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hard-of-hearing:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-hashtag-lock:before {
  content: "";
}

.fa-hat-beach:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-hard:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-haykal:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-head-side-gear:before {
  content: "";
}

.fa-head-side-goggles:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-head-side-heart:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-head-vr:before {
  content: "";
}

.fa-header:before, .fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before, .fa-headphones-simple:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heart-circle:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-heart-crack:before {
  content: "";
}

.fa-heart-half:before {
  content: "";
}

.fa-heart-half-alt:before, .fa-heart-half-stroke:before {
  content: "";
}

.fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-heart-pulse:before {
  content: "";
}

.fa-heart-rate:before {
  content: "";
}

.fa-heart-square:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-helmet-safety:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-hexagon-check:before {
  content: "";
}

.fa-hexagon-divide:before {
  content: "";
}

.fa-hexagon-exclamation:before {
  content: "";
}

.fa-hexagon-image:before {
  content: "";
}

.fa-hexagon-minus:before {
  content: "";
}

.fa-hexagon-plus:before {
  content: "";
}

.fa-hexagon-vertical-nft:before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted:before {
  content: "";
}

.fa-hexagon-xmark:before {
  content: "";
}

.fa-high-definition:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-highlighter-line:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-hockey-stick-puck:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before, .fa-home-alt:before {
  content: "";
}

.fa-home-blank:before {
  content: "";
}

.fa-home-heart:before {
  content: "";
}

.fa-home-lg:before {
  content: "";
}

.fa-home-lg-alt:before {
  content: "";
}

.fa-home-user:before {
  content: "";
}

.fa-honey-pot:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-hose:before {
  content: "";
}

.fa-hose-reel:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hospital-wide:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-hot-tub:before, .fa-hot-tub-person:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-1:before {
  content: "";
}

.fa-hourglass-2:before {
  content: "";
}

.fa-hourglass-3:before {
  content: "";
}

.fa-hourglass-clock:before {
  content: "";
}

.fa-hourglass-empty:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house:before {
  content: "";
}

.fa-house-blank:before {
  content: "";
}

.fa-house-building:before {
  content: "";
}

.fa-house-chimney:before {
  content: "";
}

.fa-house-chimney-blank:before {
  content: "";
}

.fa-house-chimney-crack:before {
  content: "";
}

.fa-house-chimney-heart:before {
  content: "";
}

.fa-house-chimney-medical:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-house-flood:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-house-heart:before {
  content: "";
}

.fa-house-laptop:before {
  content: "";
}

.fa-house-leave:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-house-person-arrive:before {
  content: "";
}

.fa-house-person-depart:before, .fa-house-person-leave:before {
  content: "";
}

.fa-house-person-return:before, .fa-house-return:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-house-tree:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-house-turret:before {
  content: "";
}

.fa-house-user:before {
  content: "";
}

.fa-house-water:before {
  content: "";
}

.fa-house-window:before {
  content: "";
}

.fa-hryvnia:before, .fa-hryvnia-sign:before {
  content: "";
}

.fa-humidity:before {
  content: "";
}

.fa-hundred-points:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-hydra:before {
  content: "";
}

.fa-hyphen:before {
  content: "-";
}

.fa-i:before {
  content: "I";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-icons-alt:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before, .fa-id-card-clip:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-ils:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-image-landscape:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-image-polaroid-user:before {
  content: "";
}

.fa-image-portrait:before {
  content: "";
}

.fa-image-slash:before {
  content: "";
}

.fa-image-user:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-images-user:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-inbox-arrow-down:before {
  content: "";
}

.fa-inbox-arrow-up:before {
  content: "";
}

.fa-inbox-full:before {
  content: "";
}

.fa-inbox-in:before {
  content: "";
}

.fa-inbox-out:before {
  content: "";
}

.fa-inboxes:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-indian-rupee:before, .fa-indian-rupee-sign:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-industry-alt:before, .fa-industry-windows:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-info-square:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-input-numeric:before {
  content: "";
}

.fa-input-pipe:before {
  content: "";
}

.fa-input-text:before {
  content: "";
}

.fa-inr:before {
  content: "";
}

.fa-institution:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-interrobang:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-inventory:before {
  content: "";
}

.fa-island-tree-palm:before, .fa-island-tropical:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jet-fighter:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-jpy:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-jug-bottle:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-kaaba:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-key-skeleton-left-right:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keyboard-brightness:before {
  content: "";
}

.fa-keyboard-brightness-low:before {
  content: "";
}

.fa-keyboard-down:before {
  content: "";
}

.fa-keyboard-left:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kit-medical:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-kiwi-fruit:before {
  content: "";
}

.fa-knife:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-krw:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-lacrosse-stick:before {
  content: "";
}

.fa-lacrosse-stick-ball:before {
  content: "";
}

.fa-ladder-water:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-lamp-street:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-landmark-alt:before, .fa-landmark-dome:before {
  content: "";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-landmark-magnifying-glass:before {
  content: "";
}

.fa-landscape:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-arrow-down:before {
  content: "";
}

.fa-laptop-binary:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-laptop-house:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laptop-mobile:before {
  content: "";
}

.fa-laptop-slash:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-lasso-sparkles:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-layer-group-minus:before {
  content: "";
}

.fa-layer-group-plus:before {
  content: "";
}

.fa-layer-minus:before {
  content: "";
}

.fa-layer-plus:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-leafy-green:before {
  content: "";
}

.fa-left:before {
  content: "";
}

.fa-left-from-bracket:before {
  content: "";
}

.fa-left-from-line:before {
  content: "";
}

.fa-left-long:before {
  content: "";
}

.fa-left-long-to-line:before {
  content: "";
}

.fa-left-right:before {
  content: "";
}

.fa-left-to-bracket:before {
  content: "";
}

.fa-left-to-line:before {
  content: "";
}

.fa-legal:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-light-emergency:before {
  content: "";
}

.fa-light-emergency-on:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-lightbulb-cfl:before {
  content: "";
}

.fa-lightbulb-cfl-on:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-lightbulb-exclamation-on:before {
  content: "";
}

.fa-lightbulb-gear:before {
  content: "";
}

.fa-lightbulb-message:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-lighthouse:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-link-horizontal:before {
  content: "";
}

.fa-link-horizontal-slash:before {
  content: "";
}

.fa-link-simple:before {
  content: "";
}

.fa-link-simple-slash:before {
  content: "";
}

.fa-link-slash:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-1-2:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-check:before {
  content: "";
}

.fa-list-dots:before {
  content: "";
}

.fa-list-dropdown:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-list-numeric:before, .fa-list-ol:before {
  content: "";
}

.fa-list-radio:before {
  content: "";
}

.fa-list-squares:before {
  content: "";
}

.fa-list-timeline:before {
  content: "";
}

.fa-list-tree:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-loader:before {
  content: "";
}

.fa-lobster:before {
  content: "";
}

.fa-location:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-location-arrow-up:before {
  content: "";
}

.fa-location-check:before {
  content: "";
}

.fa-location-circle:before {
  content: "";
}

.fa-location-crosshairs:before {
  content: "";
}

.fa-location-crosshairs-slash:before {
  content: "";
}

.fa-location-dot:before {
  content: "";
}

.fa-location-dot-slash:before {
  content: "";
}

.fa-location-exclamation:before {
  content: "";
}

.fa-location-minus:before {
  content: "";
}

.fa-location-pen:before {
  content: "";
}

.fa-location-pin:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-location-pin-slash:before {
  content: "";
}

.fa-location-plus:before {
  content: "";
}

.fa-location-question:before {
  content: "";
}

.fa-location-slash:before {
  content: "";
}

.fa-location-smile:before {
  content: "";
}

.fa-location-xmark:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-a:before {
  content: "";
}

.fa-lock-alt:before {
  content: "";
}

.fa-lock-hashtag:before {
  content: "";
}

.fa-lock-keyhole:before {
  content: "";
}

.fa-lock-keyhole-open:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-lock-open-alt:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-lollipop:before, .fa-lollypop:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-loveseat:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luchador:before, .fa-luchador-mask:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-mace:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magic-wand-sparkles:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-magnifying-glass:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-magnifying-glass-arrows-rotate:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-magnifying-glass-dollar:before {
  content: "";
}

.fa-magnifying-glass-location:before {
  content: "";
}

.fa-magnifying-glass-minus:before {
  content: "";
}

.fa-magnifying-glass-music:before {
  content: "";
}

.fa-magnifying-glass-play:before {
  content: "";
}

.fa-magnifying-glass-plus:before {
  content: "";
}

.fa-magnifying-glass-waveform:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mail-forward:before {
  content: "";
}

.fa-mail-reply:before {
  content: "";
}

.fa-mail-reply-all:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-mailbox-flag-up:before {
  content: "";
}

.fa-maki-roll:before, .fa-makizushi:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-mango:before {
  content: "";
}

.fa-manhole:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-location:before {
  content: "";
}

.fa-map-location-dot:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-marker-alt-slash:before {
  content: "";
}

.fa-map-marker-check:before {
  content: "";
}

.fa-map-marker-edit:before {
  content: "";
}

.fa-map-marker-exclamation:before {
  content: "";
}

.fa-map-marker-minus:before {
  content: "";
}

.fa-map-marker-plus:before {
  content: "";
}

.fa-map-marker-question:before {
  content: "";
}

.fa-map-marker-slash:before {
  content: "";
}

.fa-map-marker-smile:before {
  content: "";
}

.fa-map-marker-times:before, .fa-map-marker-xmark:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before, .fa-mars-stroke-right:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-martini-glass:before {
  content: "";
}

.fa-martini-glass-citrus:before {
  content: "";
}

.fa-martini-glass-empty:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-mask-luchador:before {
  content: "";
}

.fa-mask-snorkel:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-masks-theater:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-maximize:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-melon:before {
  content: "";
}

.fa-melon-slice:before {
  content: "";
}

.fa-memo:before {
  content: "";
}

.fa-memo-circle-check:before {
  content: "";
}

.fa-memo-circle-info:before {
  content: "";
}

.fa-memo-pad:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-merge:before {
  content: "";
}

.fa-message:before {
  content: "";
}

.fa-message-arrow-down:before {
  content: "";
}

.fa-message-arrow-up:before {
  content: "";
}

.fa-message-arrow-up-right:before {
  content: "";
}

.fa-message-bot:before {
  content: "";
}

.fa-message-captions:before {
  content: "";
}

.fa-message-check:before {
  content: "";
}

.fa-message-code:before {
  content: "";
}

.fa-message-dollar:before {
  content: "";
}

.fa-message-dots:before {
  content: "";
}

.fa-message-edit:before {
  content: "";
}

.fa-message-exclamation:before {
  content: "";
}

.fa-message-heart:before {
  content: "";
}

.fa-message-image:before {
  content: "";
}

.fa-message-lines:before {
  content: "";
}

.fa-message-medical:before {
  content: "";
}

.fa-message-middle:before {
  content: "";
}

.fa-message-middle-top:before {
  content: "";
}

.fa-message-minus:before {
  content: "";
}

.fa-message-music:before {
  content: "";
}

.fa-message-pen:before {
  content: "";
}

.fa-message-plus:before {
  content: "";
}

.fa-message-question:before {
  content: "";
}

.fa-message-quote:before {
  content: "";
}

.fa-message-slash:before {
  content: "";
}

.fa-message-smile:before {
  content: "";
}

.fa-message-sms:before {
  content: "";
}

.fa-message-text:before {
  content: "";
}

.fa-message-times:before, .fa-message-xmark:before {
  content: "";
}

.fa-messages:before {
  content: "";
}

.fa-messages-dollar:before {
  content: "";
}

.fa-messages-question:before {
  content: "";
}

.fa-messaging:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-meter:before {
  content: "";
}

.fa-meter-bolt:before {
  content: "";
}

.fa-meter-droplet:before {
  content: "";
}

.fa-meter-fire:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microchip-ai:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-circle:before {
  content: "";
}

.fa-microphone-circle-alt:before {
  content: "";
}

.fa-microphone-lines:before {
  content: "";
}

.fa-microphone-lines-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-mind-share:before {
  content: "";
}

.fa-minimize:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-hexagon:before {
  content: "";
}

.fa-minus-large:before {
  content: "";
}

.fa-minus-octagon:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-mobile-android:before {
  content: "";
}

.fa-mobile-android-alt:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-mobile-iphone:before, .fa-mobile-notch:before {
  content: "";
}

.fa-mobile-phone:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-mobile-screen:before {
  content: "";
}

.fa-mobile-screen-button:before {
  content: "";
}

.fa-mobile-signal:before {
  content: "";
}

.fa-mobile-signal-out:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-1:before {
  content: "";
}

.fa-money-bill-1-wave:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-simple:before {
  content: "";
}

.fa-money-bill-simple-wave:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-money-bills-alt:before, .fa-money-bills-simple:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before, .fa-money-check-dollar:before {
  content: "";
}

.fa-money-check-dollar-pen:before {
  content: "";
}

.fa-money-check-edit:before {
  content: "";
}

.fa-money-check-edit-alt:before {
  content: "";
}

.fa-money-check-pen:before {
  content: "";
}

.fa-money-from-bracket:before {
  content: "";
}

.fa-money-simple-from-bracket:before {
  content: "";
}

.fa-monitor-heart-rate:before, .fa-monitor-waveform:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-moon-over-sun:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-moped:before {
  content: "";
}

.fa-mortar-board:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-alt:before {
  content: "";
}

.fa-mouse-field:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-mug-saucer:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-mug-tea-saucer:before {
  content: "";
}

.fa-multiply:before {
  content: "";
}

.fa-museum:before {
  content: "";
}

.fa-mushroom:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-music-alt:before {
  content: "";
}

.fa-music-alt-slash:before {
  content: "";
}

.fa-music-magnifying-glass:before {
  content: "";
}

.fa-music-note:before {
  content: "";
}

.fa-music-note-slash:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-mustache:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-naira-sign:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-navicon:before {
  content: "";
}

.fa-nesting-dolls:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nfc:before {
  content: "";
}

.fa-nfc-lock:before {
  content: "";
}

.fa-nfc-magnifying-glass:before {
  content: "";
}

.fa-nfc-pen:before {
  content: "";
}

.fa-nfc-signal:before {
  content: "";
}

.fa-nfc-slash:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-nfc-trash:before {
  content: "";
}

.fa-nigiri:before {
  content: "";
}

.fa-nose:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-note:before {
  content: "";
}

.fa-note-medical:before {
  content: "";
}

.fa-note-sticky:before {
  content: "";
}

.fa-notebook:before {
  content: "";
}

.fa-notes:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-object-exclude:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-intersect:before {
  content: "";
}

.fa-object-subtract:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-object-union:before {
  content: "";
}

.fa-objects-align-bottom:before {
  content: "";
}

.fa-objects-align-center-horizontal:before {
  content: "";
}

.fa-objects-align-center-vertical:before {
  content: "";
}

.fa-objects-align-left:before {
  content: "";
}

.fa-objects-align-right:before {
  content: "";
}

.fa-objects-align-top:before {
  content: "";
}

.fa-objects-column:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-octagon-check:before {
  content: "";
}

.fa-octagon-divide:before {
  content: "";
}

.fa-octagon-exclamation:before {
  content: "";
}

.fa-octagon-minus:before {
  content: "";
}

.fa-octagon-plus:before {
  content: "";
}

.fa-octagon-xmark:before {
  content: "";
}

.fa-octopus:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-oil-can-drip:before {
  content: "";
}

.fa-oil-temp:before, .fa-oil-temperature:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-olive:before {
  content: "";
}

.fa-olive-branch:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-onion:before {
  content: "";
}

.fa-option:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-page:before {
  content: "";
}

.fa-page-break:before {
  content: "";
}

.fa-page-caret-down:before {
  content: "";
}

.fa-page-caret-up:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-brush-alt:before, .fa-paint-brush-fine:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-paintbrush:before {
  content: "";
}

.fa-paintbrush-alt:before, .fa-paintbrush-fine:before {
  content: "";
}

.fa-paintbrush-pencil:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palette-boxes:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-pallet-alt:before {
  content: "";
}

.fa-pallet-box:before {
  content: "";
}

.fa-pallet-boxes:before {
  content: "";
}

.fa-pan-food:before {
  content: "";
}

.fa-pan-frying:before {
  content: "";
}

.fa-pancakes:before {
  content: "";
}

.fa-panel-ews:before {
  content: "";
}

.fa-panel-fire:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paper-plane-alt:before, .fa-paper-plane-top:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-paperclip-vertical:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-paragraph-left:before, .fa-paragraph-rtl:before {
  content: "";
}

.fa-parentheses:before {
  content: "";
}

.fa-parenthesis:before {
  content: "(";
}

.fa-parking:before {
  content: "";
}

.fa-parking-circle:before {
  content: "";
}

.fa-parking-circle-slash:before {
  content: "";
}

.fa-parking-slash:before {
  content: "";
}

.fa-party-back:before {
  content: "";
}

.fa-party-bell:before {
  content: "";
}

.fa-party-horn:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paw-alt:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-paw-simple:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-peach:before {
  content: "";
}

.fa-peanut:before {
  content: "";
}

.fa-peanuts:before {
  content: "";
}

.fa-peapod:before {
  content: "";
}

.fa-pear:before {
  content: "";
}

.fa-pedestal:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-alt-slash:before {
  content: "";
}

.fa-pen-circle:before {
  content: "";
}

.fa-pen-clip:before {
  content: "";
}

.fa-pen-clip-slash:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-fancy-slash:before {
  content: "";
}

.fa-pen-field:before {
  content: "";
}

.fa-pen-line:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-nib-slash:before {
  content: "";
}

.fa-pen-paintbrush:before {
  content: "";
}

.fa-pen-ruler:before {
  content: "";
}

.fa-pen-slash:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pen-swirl:before {
  content: "";
}

.fa-pen-to-square:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-pencil-mechanical:before {
  content: "";
}

.fa-pencil-paintbrush:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-pencil-slash:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-pennant:before {
  content: "";
}

.fa-people:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-people-carry:before, .fa-people-carry-box:before {
  content: "";
}

.fa-people-dress:before {
  content: "";
}

.fa-people-dress-simple:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-people-pants:before {
  content: "";
}

.fa-people-pants-simple:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-simple:before {
  content: "";
}

.fa-pepper:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-period:before {
  content: ".";
}

.fa-person:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-person-biking:before {
  content: "";
}

.fa-person-biking-mountain:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-person-carry:before, .fa-person-carry-box:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-person-digging:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-person-dots-from-line:before {
  content: "";
}

.fa-person-dress:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-person-dress-fairy:before {
  content: "";
}

.fa-person-dress-simple:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-person-fairy:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-person-from-portal:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-person-hiking:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-person-pinball:before {
  content: "";
}

.fa-person-praying:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-person-running:before {
  content: "";
}

.fa-person-running-fast:before {
  content: "";
}

.fa-person-seat:before {
  content: "";
}

.fa-person-seat-reclined:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-person-simple:before {
  content: "";
}

.fa-person-skating:before {
  content: "";
}

.fa-person-ski-jumping:before {
  content: "";
}

.fa-person-ski-lift:before {
  content: "";
}

.fa-person-skiing:before {
  content: "";
}

.fa-person-skiing-nordic:before {
  content: "";
}

.fa-person-sledding:before {
  content: "";
}

.fa-person-snowboarding:before {
  content: "";
}

.fa-person-snowmobiling:before {
  content: "";
}

.fa-person-swimming:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-person-to-door:before {
  content: "";
}

.fa-person-to-portal:before {
  content: "";
}

.fa-person-walking:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-person-walking-with-cane:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-arrow-down:before, .fa-phone-arrow-down-left:before {
  content: "";
}

.fa-phone-arrow-right:before {
  content: "";
}

.fa-phone-arrow-up:before, .fa-phone-arrow-up-right:before {
  content: "";
}

.fa-phone-circle:before {
  content: "";
}

.fa-phone-circle-alt:before {
  content: "";
}

.fa-phone-circle-down:before {
  content: "";
}

.fa-phone-flip:before {
  content: "";
}

.fa-phone-hangup:before {
  content: "";
}

.fa-phone-incoming:before {
  content: "";
}

.fa-phone-intercom:before {
  content: "";
}

.fa-phone-laptop:before {
  content: "";
}

.fa-phone-missed:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-phone-outgoing:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-square-down:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-phone-xmark:before {
  content: "";
}

.fa-photo-film:before {
  content: "";
}

.fa-photo-film-music:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-pickaxe:before {
  content: "";
}

.fa-pickleball:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinata:before {
  content: "";
}

.fa-pinball:before {
  content: "";
}

.fa-pineapple:before {
  content: "";
}

.fa-ping-pong-paddle-ball:before {
  content: "";
}

.fa-pipe:before {
  content: "|";
}

.fa-pipe-circle-check:before {
  content: "";
}

.fa-pipe-collar:before {
  content: "";
}

.fa-pipe-section:before {
  content: "";
}

.fa-pipe-smoking:before {
  content: "";
}

.fa-pipe-valve:before {
  content: "";
}

.fa-pizza:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-alt:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-engines:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-plane-prop:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-plane-tail:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-plane-up-slash:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-plate-utensils:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-play-pause:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-plus:before {
  content: "+";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-hexagon:before {
  content: "";
}

.fa-plus-large:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-plus-octagon:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-pompebled:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-bolt:before, .fa-poo-storm:before {
  content: "";
}

.fa-pool-8-ball:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-popsicle:before {
  content: "";
}

.fa-portal-enter:before {
  content: "";
}

.fa-portal-exit:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pot-food:before {
  content: "";
}

.fa-potato:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before, .fa-prescription-bottle-medical:before {
  content: "";
}

.fa-prescription-bottle-pill:before {
  content: "";
}

.fa-presentation:before, .fa-presentation-screen:before {
  content: "";
}

.fa-pretzel:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-print-magnifying-glass:before, .fa-print-search:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-pro:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-pump:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-puzzle:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-puzzle-piece-alt:before, .fa-puzzle-piece-simple:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-question-circle:before {
  content: "";
}

.fa-question-square:before {
  content: "";
}

.fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-quotes:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-rabbit:before {
  content: "";
}

.fa-rabbit-fast:before, .fa-rabbit-running:before {
  content: "";
}

.fa-raccoon:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-radio-alt:before, .fa-radio-tuner:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-rectangle:before {
  content: "";
}

.fa-rectangle-ad:before {
  content: "";
}

.fa-rectangle-barcode:before {
  content: "";
}

.fa-rectangle-code:before {
  content: "";
}

.fa-rectangle-hd:before {
  content: "";
}

.fa-rectangle-history:before {
  content: "";
}

.fa-rectangle-history-circle-plus:before {
  content: "";
}

.fa-rectangle-history-circle-user:before {
  content: "";
}

.fa-rectangle-landscape:before {
  content: "";
}

.fa-rectangle-list:before {
  content: "";
}

.fa-rectangle-portrait:before {
  content: "";
}

.fa-rectangle-pro:before {
  content: "";
}

.fa-rectangle-sd:before {
  content: "";
}

.fa-rectangle-terminal:before {
  content: "";
}

.fa-rectangle-times:before {
  content: "";
}

.fa-rectangle-vertical:before {
  content: "";
}

.fa-rectangle-vertical-history:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-rectangle-xmark:before {
  content: "";
}

.fa-rectangles-mixed:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-reel:before {
  content: "";
}

.fa-reflect-both:before {
  content: "";
}

.fa-reflect-horizontal:before {
  content: "";
}

.fa-reflect-vertical:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-reorder:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-repeat-1-alt:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-reply-clock:before, .fa-reply-time:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-restroom-simple:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-retweet-alt:before {
  content: "";
}

.fa-rhombus:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-right:before {
  content: "";
}

.fa-right-from-bracket:before {
  content: "";
}

.fa-right-from-line:before {
  content: "";
}

.fa-right-left:before {
  content: "";
}

.fa-right-left-large:before {
  content: "";
}

.fa-right-long:before {
  content: "";
}

.fa-right-long-to-line:before {
  content: "";
}

.fa-right-to-bracket:before {
  content: "";
}

.fa-right-to-line:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-ring-diamond:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-rmb:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-robot-astromech:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-rod-asclepius:before, .fa-rod-snake:before {
  content: "";
}

.fa-roller-coaster:before {
  content: "";
}

.fa-rotate:before {
  content: "";
}

.fa-rotate-back:before, .fa-rotate-backward:before {
  content: "";
}

.fa-rotate-exclamation:before {
  content: "";
}

.fa-rotate-forward:before {
  content: "";
}

.fa-rotate-left:before {
  content: "";
}

.fa-rotate-reverse:before {
  content: "";
}

.fa-rotate-right:before {
  content: "";
}

.fa-rouble:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-rows:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-rub:before, .fa-ruble:before, .fa-ruble-sign:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-rugby-ball:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee:before, .fa-rupee-sign:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-sack:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-salad:before {
  content: "";
}

.fa-salt-shaker:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-save-circle-arrow-right:before {
  content: "";
}

.fa-save-circle-xmark:before, .fa-save-times:before {
  content: "";
}

.fa-sax-hot:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-saxophone-fire:before {
  content: "";
}

.fa-scale-balanced:before {
  content: "";
}

.fa-scale-unbalanced:before {
  content: "";
}

.fa-scale-unbalanced-flip:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-scalpel-line-dashed:before, .fa-scalpel-path:before {
  content: "";
}

.fa-scanner:before, .fa-scanner-gun:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-scissors:before {
  content: "";
}

.fa-screen-users:before {
  content: "";
}

.fa-screencast:before {
  content: "";
}

.fa-screenshot:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-screwdriver-wrench:before {
  content: "";
}

.fa-scribble:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-scroll-ribbon:before {
  content: "";
}

.fa-scroll-torah:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-sd-cards:before {
  content: "";
}

.fa-seal:before {
  content: "";
}

.fa-seal-exclamation:before {
  content: "";
}

.fa-seal-question:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-seat-airline:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-semicolon:before {
  content: ";";
}

.fa-send:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-sensor-alert:before {
  content: "";
}

.fa-sensor-cloud:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-sensor-smoke:before {
  content: "";
}

.fa-sensor-triangle-exclamation:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-from-square:before {
  content: "";
}

.fa-share-nodes:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-shekel:before, .fa-shekel-sign:before {
  content: "";
}

.fa-shelves:before {
  content: "";
}

.fa-shelves-empty:before {
  content: "";
}

.fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-blank:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-shield-exclamation:before {
  content: "";
}

.fa-shield-halved:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-shield-keyhole:before {
  content: "";
}

.fa-shield-minus:before {
  content: "";
}

.fa-shield-plus:before {
  content: "";
}

.fa-shield-quartered:before {
  content: "";
}

.fa-shield-slash:before {
  content: "";
}

.fa-shield-times:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-shield-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shipping-timed:before {
  content: "";
}

.fa-shirt:before {
  content: "";
}

.fa-shirt-long-sleeve:before {
  content: "";
}

.fa-shirt-running:before {
  content: "";
}

.fa-shirt-tank-top:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shop:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-shop-slash:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-basket-alt:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shortcake:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shower-alt:before, .fa-shower-down:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-shuffle:before {
  content: "";
}

.fa-shutters:before {
  content: "";
}

.fa-shuttle-space:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-sidebar:before {
  content: "";
}

.fa-sidebar-flip:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-sign:before, .fa-sign-hanging:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-sign-post:before {
  content: "";
}

.fa-sign-posts:before {
  content: "";
}

.fa-sign-posts-wrench:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signal-1:before {
  content: "";
}

.fa-signal-2:before {
  content: "";
}

.fa-signal-3:before {
  content: "";
}

.fa-signal-4:before {
  content: "";
}

.fa-signal-5:before {
  content: "";
}

.fa-signal-alt:before {
  content: "";
}

.fa-signal-alt-1:before {
  content: "";
}

.fa-signal-alt-2:before {
  content: "";
}

.fa-signal-alt-3:before {
  content: "";
}

.fa-signal-alt-4:before {
  content: "";
}

.fa-signal-alt-slash:before {
  content: "";
}

.fa-signal-bars:before {
  content: "";
}

.fa-signal-bars-fair:before {
  content: "";
}

.fa-signal-bars-good:before {
  content: "";
}

.fa-signal-bars-slash:before {
  content: "";
}

.fa-signal-bars-strong:before {
  content: "";
}

.fa-signal-bars-weak:before {
  content: "";
}

.fa-signal-fair:before {
  content: "";
}

.fa-signal-good:before {
  content: "";
}

.fa-signal-perfect:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-signal-stream:before {
  content: "";
}

.fa-signal-stream-slash:before {
  content: "";
}

.fa-signal-strong:before {
  content: "";
}

.fa-signal-weak:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-signature-lock:before {
  content: "";
}

.fa-signature-slash:before {
  content: "";
}

.fa-signing:before {
  content: "";
}

.fa-signs-post:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-sim-cards:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-skeleton-ribs:before {
  content: "";
}

.fa-ski-boot:before {
  content: "";
}

.fa-ski-boot-ski:before {
  content: "";
}

.fa-ski-jump:before {
  content: "";
}

.fa-ski-lift:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-slash-back:before {
  content: "\\";
}

.fa-slash-forward:before {
  content: "/";
}

.fa-sledding:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-slider:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-sliders-h-square:before {
  content: "";
}

.fa-sliders-simple:before {
  content: "";
}

.fa-sliders-up:before, .fa-sliders-v:before {
  content: "";
}

.fa-sliders-v-square:before {
  content: "";
}

.fa-slot-machine:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-plus:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-snooze:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowflake-droplets:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowman-head:before {
  content: "";
}

.fa-snowmobile:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-soccer-ball:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-soft-serve:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-alt:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-asc:before {
  content: "";
}

.fa-sort-circle:before {
  content: "";
}

.fa-sort-circle-down:before {
  content: "";
}

.fa-sort-circle-up:before {
  content: "";
}

.fa-sort-desc:before, .fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-shapes-down:before {
  content: "";
}

.fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-sort-shapes-up:before {
  content: "";
}

.fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-sort-size-down:before {
  content: "";
}

.fa-sort-size-down-alt:before {
  content: "";
}

.fa-sort-size-up:before {
  content: "";
}

.fa-sort-size-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-sort-up-down:before {
  content: "";
}

.fa-soup:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-space-station-moon-alt:before, .fa-space-station-moon-construction:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-spaghetti-monster-flying:before {
  content: "";
}

.fa-sparkle:before {
  content: "";
}

.fa-sparkles:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-spinner-scale:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-split:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-sportsball:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-spray-can-sparkles:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-sprinkler-ceiling:before {
  content: "";
}

.fa-sprout:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-0:before {
  content: "";
}

.fa-square-1:before {
  content: "";
}

.fa-square-2:before {
  content: "";
}

.fa-square-3:before {
  content: "";
}

.fa-square-4:before {
  content: "";
}

.fa-square-5:before {
  content: "";
}

.fa-square-6:before {
  content: "";
}

.fa-square-7:before {
  content: "";
}

.fa-square-8:before {
  content: "";
}

.fa-square-9:before {
  content: "";
}

.fa-square-a:before {
  content: "";
}

.fa-square-a-lock:before {
  content: "";
}

.fa-square-ampersand:before {
  content: "";
}

.fa-square-arrow-down:before {
  content: "";
}

.fa-square-arrow-down-left:before {
  content: "";
}

.fa-square-arrow-down-right:before {
  content: "";
}

.fa-square-arrow-left:before {
  content: "";
}

.fa-square-arrow-right:before {
  content: "";
}

.fa-square-arrow-up:before {
  content: "";
}

.fa-square-arrow-up-left:before {
  content: "";
}

.fa-square-arrow-up-right:before {
  content: "";
}

.fa-square-b:before {
  content: "";
}

.fa-square-bolt:before {
  content: "";
}

.fa-square-c:before {
  content: "";
}

.fa-square-caret-down:before {
  content: "";
}

.fa-square-caret-left:before {
  content: "";
}

.fa-square-caret-right:before {
  content: "";
}

.fa-square-caret-up:before {
  content: "";
}

.fa-square-check:before {
  content: "";
}

.fa-square-chevron-down:before {
  content: "";
}

.fa-square-chevron-left:before {
  content: "";
}

.fa-square-chevron-right:before {
  content: "";
}

.fa-square-chevron-up:before {
  content: "";
}

.fa-square-code:before {
  content: "";
}

.fa-square-d:before {
  content: "";
}

.fa-square-dashed:before {
  content: "";
}

.fa-square-dashed-circle-plus:before {
  content: "";
}

.fa-square-divide:before {
  content: "";
}

.fa-square-dollar:before {
  content: "";
}

.fa-square-down:before {
  content: "";
}

.fa-square-down-left:before {
  content: "";
}

.fa-square-down-right:before {
  content: "";
}

.fa-square-e:before {
  content: "";
}

.fa-square-ellipsis:before {
  content: "";
}

.fa-square-ellipsis-vertical:before {
  content: "";
}

.fa-square-envelope:before {
  content: "";
}

.fa-square-exclamation:before {
  content: "";
}

.fa-square-f:before {
  content: "";
}

.fa-square-fragile:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-g:before {
  content: "";
}

.fa-square-h:before {
  content: "";
}

.fa-square-heart:before {
  content: "";
}

.fa-square-i:before {
  content: "";
}

.fa-square-info:before {
  content: "";
}

.fa-square-j:before {
  content: "";
}

.fa-square-k:before {
  content: "";
}

.fa-square-kanban:before {
  content: "";
}

.fa-square-l:before {
  content: "";
}

.fa-square-left:before {
  content: "";
}

.fa-square-list:before {
  content: "";
}

.fa-square-m:before {
  content: "";
}

.fa-square-minus:before {
  content: "";
}

.fa-square-n:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-square-p:before {
  content: "";
}

.fa-square-parking:before {
  content: "";
}

.fa-square-parking-slash:before {
  content: "";
}

.fa-square-pen:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-square-phone:before {
  content: "";
}

.fa-square-phone-flip:before {
  content: "";
}

.fa-square-phone-hangup:before {
  content: "";
}

.fa-square-plus:before {
  content: "";
}

.fa-square-poll-horizontal:before {
  content: "";
}

.fa-square-poll-vertical:before {
  content: "";
}

.fa-square-q:before {
  content: "";
}

.fa-square-quarters:before {
  content: "";
}

.fa-square-question:before {
  content: "";
}

.fa-square-quote:before {
  content: "";
}

.fa-square-r:before {
  content: "";
}

.fa-square-right:before {
  content: "";
}

.fa-square-ring:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-square-root-alt:before, .fa-square-root-variable:before {
  content: "";
}

.fa-square-rss:before {
  content: "";
}

.fa-square-s:before {
  content: "";
}

.fa-square-share-nodes:before {
  content: "";
}

.fa-square-sliders:before {
  content: "";
}

.fa-square-sliders-vertical:before {
  content: "";
}

.fa-square-small:before {
  content: "";
}

.fa-square-star:before {
  content: "";
}

.fa-square-t:before {
  content: "";
}

.fa-square-terminal:before {
  content: "";
}

.fa-square-this-way-up:before {
  content: "";
}

.fa-square-u:before {
  content: "";
}

.fa-square-up:before {
  content: "";
}

.fa-square-up-left:before {
  content: "";
}

.fa-square-up-right:before {
  content: "";
}

.fa-square-user:before {
  content: "";
}

.fa-square-v:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-square-w:before {
  content: "";
}

.fa-square-wine-glass-crack:before {
  content: "";
}

.fa-square-x:before {
  content: "";
}

.fa-square-xmark:before {
  content: "";
}

.fa-square-y:before {
  content: "";
}

.fa-square-z:before {
  content: "";
}

.fa-squid:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-staff-aesculapius:before, .fa-staff-snake:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-standard-definition:before {
  content: "";
}

.fa-stapler:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-star-circle:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before, .fa-star-half-stroke:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-star-sharp:before {
  content: "";
}

.fa-star-sharp-half:before {
  content: "";
}

.fa-star-sharp-half-alt:before, .fa-star-sharp-half-stroke:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-starfighter-alt:before {
  content: "";
}

.fa-starfighter-alt-advanced:before {
  content: "";
}

.fa-starfighter-twin-ion-engine:before {
  content: "";
}

.fa-starfighter-twin-ion-engine-advanced:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-sterling-sign:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "";
}

.fa-store-lock:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-strawberry:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subtitles:before {
  content: "";
}

.fa-subtitles-slash:before {
  content: "";
}

.fa-subtract:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-subway-tunnel:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-medical:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-sun-alt:before, .fa-sun-bright:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-sushi:before {
  content: "";
}

.fa-sushi-roll:before {
  content: "";
}

.fa-swap:before {
  content: "";
}

.fa-swap-arrows:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-symbols:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-t-rex:before {
  content: "";
}

.fa-t-shirt:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-cells:before {
  content: "";
}

.fa-table-cells-column-lock:before {
  content: "";
}

.fa-table-cells-column-unlock:before {
  content: "";
}

.fa-table-cells-large:before {
  content: "";
}

.fa-table-cells-lock:before {
  content: "";
}

.fa-table-cells-row-lock:before {
  content: "";
}

.fa-table-cells-row-unlock:before {
  content: "";
}

.fa-table-cells-unlock:before {
  content: "";
}

.fa-table-columns:before {
  content: "";
}

.fa-table-layout:before {
  content: "";
}

.fa-table-list:before {
  content: "";
}

.fa-table-picnic:before {
  content: "";
}

.fa-table-pivot:before {
  content: "";
}

.fa-table-rows:before {
  content: "";
}

.fa-table-tennis:before, .fa-table-tennis-paddle-ball:before {
  content: "";
}

.fa-table-tree:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablet-android:before {
  content: "";
}

.fa-tablet-android-alt:before {
  content: "";
}

.fa-tablet-button:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-tablet-screen:before {
  content: "";
}

.fa-tablet-screen-button:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachograph-digital:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tachometer-alt-average:before {
  content: "";
}

.fa-tachometer-alt-fast:before {
  content: "";
}

.fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-tachometer-alt-slow:before {
  content: "";
}

.fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-tachometer-average:before {
  content: "";
}

.fa-tachometer-fast:before {
  content: "";
}

.fa-tachometer-fastest:before {
  content: "";
}

.fa-tachometer-slow:before {
  content: "";
}

.fa-tachometer-slowest:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tally:before {
  content: "";
}

.fa-tally-1:before {
  content: "";
}

.fa-tally-2:before {
  content: "";
}

.fa-tally-3:before {
  content: "";
}

.fa-tally-4:before {
  content: "";
}

.fa-tally-5:before {
  content: "";
}

.fa-tamale:before {
  content: "";
}

.fa-tanakh:before {
  content: "";
}

.fa-tank-water:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tarp:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-tasks-alt:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-taxi-bus:before {
  content: "";
}

.fa-teddy-bear:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-teletype:before {
  content: "";
}

.fa-teletype-answer:before {
  content: "";
}

.fa-television:before {
  content: "";
}

.fa-temperature-0:before {
  content: "";
}

.fa-temperature-1:before {
  content: "";
}

.fa-temperature-2:before {
  content: "";
}

.fa-temperature-3:before {
  content: "";
}

.fa-temperature-4:before {
  content: "";
}

.fa-temperature-arrow-down:before {
  content: "";
}

.fa-temperature-arrow-up:before {
  content: "";
}

.fa-temperature-down:before {
  content: "";
}

.fa-temperature-empty:before {
  content: "";
}

.fa-temperature-frigid:before {
  content: "";
}

.fa-temperature-full:before {
  content: "";
}

.fa-temperature-half:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-hot:before {
  content: "";
}

.fa-temperature-list:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-temperature-quarter:before {
  content: "";
}

.fa-temperature-snow:before {
  content: "";
}

.fa-temperature-sun:before {
  content: "";
}

.fa-temperature-three-quarters:before {
  content: "";
}

.fa-temperature-up:before {
  content: "";
}

.fa-tenge:before, .fa-tenge-sign:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-tent-double-peak:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-text-slash:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-0:before {
  content: "";
}

.fa-thermometer-1:before {
  content: "";
}

.fa-thermometer-2:before {
  content: "";
}

.fa-thermometer-3:before {
  content: "";
}

.fa-thermometer-4:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-thought-bubble:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-thumb-tack-slash:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-thumbtack-slash:before {
  content: "";
}

.fa-thunderstorm:before {
  content: "";
}

.fa-thunderstorm-moon:before {
  content: "";
}

.fa-thunderstorm-sun:before {
  content: "";
}

.fa-tick:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-ticket-airline:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-ticket-perforated:before {
  content: "";
}

.fa-ticket-perforated-plane:before, .fa-ticket-plane:before {
  content: "";
}

.fa-ticket-simple:before {
  content: "";
}

.fa-tickets:before {
  content: "";
}

.fa-tickets-airline:before {
  content: "";
}

.fa-tickets-perforated:before {
  content: "";
}

.fa-tickets-perforated-plane:before, .fa-tickets-plane:before {
  content: "";
}

.fa-tickets-simple:before {
  content: "";
}

.fa-tilde:before {
  content: "~";
}

.fa-timeline:before {
  content: "";
}

.fa-timeline-arrow:before {
  content: "";
}

.fa-timer:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-times-hexagon:before {
  content: "";
}

.fa-times-octagon:before {
  content: "";
}

.fa-times-rectangle:before {
  content: "";
}

.fa-times-square:before {
  content: "";
}

.fa-times-to-slot:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-large-off:before {
  content: "";
}

.fa-toggle-large-on:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-alt:before, .fa-toilet-paper-blank:before {
  content: "";
}

.fa-toilet-paper-blank-under:before {
  content: "";
}

.fa-toilet-paper-check:before {
  content: "";
}

.fa-toilet-paper-reverse:before {
  content: "";
}

.fa-toilet-paper-reverse-alt:before {
  content: "";
}

.fa-toilet-paper-reverse-slash:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-toilet-paper-under:before {
  content: "";
}

.fa-toilet-paper-under-slash:before {
  content: "";
}

.fa-toilet-paper-xmark:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-tomato:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-tombstone-alt:before, .fa-tombstone-blank:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-tower-broadcast:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-tower-control:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-train-subway:before {
  content: "";
}

.fa-train-subway-tunnel:before {
  content: "";
}

.fa-train-track:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-train-tunnel:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transformer-bolt:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-transporter-4:before {
  content: "";
}

.fa-transporter-5:before {
  content: "";
}

.fa-transporter-6:before {
  content: "";
}

.fa-transporter-7:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-alt-slash:before {
  content: "";
}

.fa-trash-arrow-turn-left:before {
  content: "";
}

.fa-trash-arrow-up:before {
  content: "";
}

.fa-trash-can:before {
  content: "";
}

.fa-trash-can-arrow-turn-left:before {
  content: "";
}

.fa-trash-can-arrow-up:before {
  content: "";
}

.fa-trash-can-check:before {
  content: "";
}

.fa-trash-can-clock:before {
  content: "";
}

.fa-trash-can-list:before {
  content: "";
}

.fa-trash-can-plus:before {
  content: "";
}

.fa-trash-can-slash:before {
  content: "";
}

.fa-trash-can-undo:before {
  content: "";
}

.fa-trash-can-xmark:before {
  content: "";
}

.fa-trash-check:before {
  content: "";
}

.fa-trash-circle:before {
  content: "";
}

.fa-trash-clock:before {
  content: "";
}

.fa-trash-list:before {
  content: "";
}

.fa-trash-plus:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-trash-slash:before {
  content: "";
}

.fa-trash-undo:before {
  content: "";
}

.fa-trash-undo-alt:before {
  content: "";
}

.fa-trash-xmark:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-tree-alt:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-tree-deciduous:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-trian-balbot:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-triangle-circle-square:before {
  content: "";
}

.fa-triangle-exclamation:before {
  content: "";
}

.fa-triangle-instrument:before, .fa-triangle-music:before {
  content: "";
}

.fa-triangle-person-digging:before {
  content: "";
}

.fa-tricycle:before {
  content: "";
}

.fa-tricycle-adult:before {
  content: "";
}

.fa-trillium:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-trophy-alt:before, .fa-trophy-star:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-truck-bolt:before {
  content: "";
}

.fa-truck-clock:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-truck-container-empty:before {
  content: "";
}

.fa-truck-couch:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-truck-fast:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-truck-fire:before {
  content: "";
}

.fa-truck-flatbed:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-truck-ladder:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-medical:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-truck-ramp-box:before {
  content: "";
}

.fa-truck-ramp-couch:before {
  content: "";
}

.fa-truck-tow:before {
  content: "";
}

.fa-truck-utensils:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-try:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tty-answer:before {
  content: "";
}

.fa-tugrik-sign:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-turkish-lira:before, .fa-turkish-lira-sign:before {
  content: "";
}

.fa-turn-down:before {
  content: "";
}

.fa-turn-down-left:before {
  content: "";
}

.fa-turn-down-right:before {
  content: "";
}

.fa-turn-left:before {
  content: "";
}

.fa-turn-left-down:before {
  content: "";
}

.fa-turn-left-up:before {
  content: "";
}

.fa-turn-right:before {
  content: "";
}

.fa-turn-up:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-tv:before, .fa-tv-alt:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-ufo:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-alt:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-umbrella-simple:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-uniform-martial-arts:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before, .fa-unlock-keyhole:before {
  content: "";
}

.fa-unsorted:before {
  content: "";
}

.fa-up:before {
  content: "";
}

.fa-up-down:before {
  content: "";
}

.fa-up-down-left-right:before {
  content: "";
}

.fa-up-from-bracket:before {
  content: "";
}

.fa-up-from-dotted-line:before {
  content: "";
}

.fa-up-from-line:before {
  content: "";
}

.fa-up-left:before {
  content: "";
}

.fa-up-long:before {
  content: "";
}

.fa-up-right:before {
  content: "";
}

.fa-up-right-and-down-left-from-center:before {
  content: "";
}

.fa-up-right-from-square:before {
  content: "";
}

.fa-up-to-bracket:before {
  content: "";
}

.fa-up-to-dotted-line:before {
  content: "";
}

.fa-up-to-line:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-usd:before {
  content: "$";
}

.fa-usd-circle:before {
  content: "";
}

.fa-usd-square:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-beard-bolt:before {
  content: "";
}

.fa-user-bounty-hunter:before {
  content: "";
}

.fa-user-chart:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-chef:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-construction:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-user-doctor:before {
  content: "";
}

.fa-user-doctor-hair:before {
  content: "";
}

.fa-user-doctor-hair-long:before {
  content: "";
}

.fa-user-doctor-message:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-gear:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-group:before {
  content: "";
}

.fa-user-group-crown:before {
  content: "";
}

.fa-user-group-simple:before {
  content: "";
}

.fa-user-hair:before {
  content: "";
}

.fa-user-hair-buns:before {
  content: "";
}

.fa-user-hair-long:before {
  content: "";
}

.fa-user-hair-mullet:before {
  content: "";
}

.fa-user-hard-hat:before {
  content: "";
}

.fa-user-headset:before {
  content: "";
}

.fa-user-helmet-safety:before {
  content: "";
}

.fa-user-hoodie:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-large:before {
  content: "";
}

.fa-user-large-slash:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-magnifying-glass:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-md-chat:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-nurse-hair:before {
  content: "";
}

.fa-user-nurse-hair-long:before {
  content: "";
}

.fa-user-pen:before {
  content: "";
}

.fa-user-pilot:before {
  content: "";
}

.fa-user-pilot-tie:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-police:before {
  content: "";
}

.fa-user-police-tie:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-user-robot-xmarks:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shakespeare:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-tie-hair:before {
  content: "";
}

.fa-user-tie-hair-long:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-user-vneck:before {
  content: "";
}

.fa-user-vneck-hair:before {
  content: "";
}

.fa-user-vneck-hair-long:before {
  content: "";
}

.fa-user-xmark:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-users-class:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-crown:before {
  content: "";
}

.fa-users-gear:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-utensil-fork:before {
  content: "";
}

.fa-utensil-knife:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-utensils-alt:before {
  content: "";
}

.fa-utensils-slash:before {
  content: "";
}

.fa-utility-pole:before {
  content: "";
}

.fa-utility-pole-double:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-vacuum:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-van-shuttle:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-vcard:before {
  content: "";
}

.fa-vector-circle:before {
  content: "";
}

.fa-vector-polygon:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-vent-damper:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-vhs:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-arrow-down-left:before {
  content: "";
}

.fa-video-arrow-up-right:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-video-circle:before {
  content: "";
}

.fa-video-handheld:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-volume:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-high:before {
  content: "";
}

.fa-volume-low:before {
  content: "";
}

.fa-volume-medium:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-volume-times:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-volume-xmark:before {
  content: "";
}

.fa-vote-nay:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-waffle:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wall-brick:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-wand-magic:before {
  content: "";
}

.fa-wand-magic-sparkles:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-warehouse-alt:before, .fa-warehouse-full:before {
  content: "";
}

.fa-warning:before {
  content: "";
}

.fa-washer:before, .fa-washing-machine:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-watch-apple:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-watch-smart:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-water-arrow-down:before {
  content: "";
}

.fa-water-arrow-up:before {
  content: "";
}

.fa-water-ladder:before {
  content: "";
}

.fa-water-lower:before {
  content: "";
}

.fa-water-rise:before {
  content: "";
}

.fa-watermelon-slice:before {
  content: "";
}

.fa-wave:before {
  content: "";
}

.fa-wave-pulse:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-waveform-circle:before {
  content: "";
}

.fa-waveform-lines:before, .fa-waveform-path:before {
  content: "";
}

.fa-waves-sine:before {
  content: "";
}

.fa-web-awesome:before {
  content: "";
}

.fa-webcam:before {
  content: "";
}

.fa-webcam-slash:before {
  content: "";
}

.fa-webhook:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weight-scale:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-wheat-alt:before, .fa-wheat-awn:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-wheat-awn-slash:before {
  content: "";
}

.fa-wheat-slash:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-wheelchair-alt:before, .fa-wheelchair-move:before {
  content: "";
}

.fa-whiskey-glass:before {
  content: "";
}

.fa-whiskey-glass-ice:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wifi-1:before {
  content: "";
}

.fa-wifi-2:before {
  content: "";
}

.fa-wifi-3:before {
  content: "";
}

.fa-wifi-exclamation:before {
  content: "";
}

.fa-wifi-fair:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-wifi-strong:before {
  content: "";
}

.fa-wifi-weak:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-wind-circle-exclamation:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-wind-warning:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-window-alt:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-flip:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wine-glass-crack:before {
  content: "";
}

.fa-wine-glass-empty:before {
  content: "";
}

.fa-won:before, .fa-won-sign:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-wreath-laurel:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-wrench-simple:before {
  content: "";
}

.fa-x:before {
  content: "X";
}

.fa-x-ray:before {
  content: "";
}

.fa-xmark:before {
  content: "";
}

.fa-xmark-circle:before {
  content: "";
}

.fa-xmark-hexagon:before {
  content: "";
}

.fa-xmark-large:before {
  content: "";
}

.fa-xmark-octagon:before {
  content: "";
}

.fa-xmark-square:before {
  content: "";
}

.fa-xmark-to-slot:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-yen:before, .fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-zap:before {
  content: "";
}

.fa-zzz:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.f55c75d9.woff2") format("woff2"), url("fa-regular-400.1d05219f.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.f60c4e73.woff2") format("woff2"), url("fa-solid-900.003b4e2f.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg, .leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font: 12px / 1.5 Helvetica Neue, Arial, Helvetica, sans-serif;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a, .leaflet-bar a:hover {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow: hidden scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #ffffffb3;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution, .leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  background: #ffffff80;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  font-size: 11px;
  line-height: 1.1;
  overflow: hidden;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #c3c3c3;
  background: none;
  border: none;
  width: 18px;
  height: 14px;
  padding: 4px 4px 0 0;
  font: bold 16px / 14px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

.leaflet-control-locate a {
  color: #444;
  cursor: pointer;
  font-size: 1.4em;
}

.leaflet-control-locate.active a {
  color: #2074b6;
}

.leaflet-control-locate.active.following a {
  color: #fc8428;
}

.leaflet-control-locate-location circle {
  animation: 4s infinite leaflet-control-locate-throb;
}

@keyframes leaflet-control-locate-throb {
  0% {
    stroke-width: 1px;
  }

  50% {
    stroke-width: 3px;
    transform: scale(.8);
  }

  100% {
    stroke-width: 1px;
  }
}

.rangeSlider, .rangeSlider__fill {
  border-radius: 10px;
  display: block;
  box-shadow: inset 0 1px 3px #0000004d;
}

.rangeSlider {
  background: #7f8c8d;
  position: relative;
}

.rangeSlider__horizontal {
  width: 100%;
  height: 20px;
}

.rangeSlider__vertical {
  width: 20px;
  height: 100%;
}

.rangeSlider--disabled {
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 40);
  opacity: .4;
}

.rangeSlider__fill {
  background: #16a085;
  position: absolute;
}

.rangeSlider__fill__horizontal {
  height: 100%;
  top: 0;
  left: 0;
}

.rangeSlider__fill__vertical {
  width: 100%;
  bottom: 0;
  left: 0;
}

.rangeSlider__handle {
  cursor: pointer;
  background: #fff linear-gradient(#fff0, #0000001a);
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 8px #0000004d;
}

.rangeSlider__handle__horizontal {
  top: -10px;
}

.rangeSlider__handle__vertical {
  bottom: 0;
  left: -10px;
}

.rangeSlider__handle:after {
  content: "";
  background-image: linear-gradient(#00000021, #fff0);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.rangeSlider__handle:active {
  background-image: linear-gradient(#0000001a, #0000001f);
}

input[type="range"]:focus + .rangeSlider .rangeSlider__handle {
  box-shadow: 0 0 8px #8e44ade6;
}

.rangeSlider__buffer {
  background: #2c3e50;
  border-radius: 10px;
  height: 14px;
  position: absolute;
  top: 3px;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  clear: both;
  display: table;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  min-height: 1px;
  position: relative;
  left: 0;
}

.tns-gallery > .tns-item {
  transition: transform, opacity;
  position: absolute;
  left: -100%;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all .25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity= 100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity= 0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  width: 310px;
  height: 10px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.tns-t-ct {
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.33%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  clear: both;
  display: table;
}

.tns-t-ct > div {
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  float: left;
  width: 1.42857%;
  height: 10px;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  white-space: inherit;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

.modal__overlay {
  z-index: 102;
  background: #0009;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal__container {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 120px);
  padding: 30px 30px 0;
  overflow-y: auto;
}

#map_modal[data-modal="wind_sensor"] .modal__container, #map_modal[data-modal="webcam"] .modal__container {
  width: calc(100vw - 40px);
}

#map_modal[data-modal="amp"] h2 {
  color: #7c2fab;
}

#map_modal #map_modal_content > div > .img-icon {
  width: 40px;
}

#map_modal[data-modal="wind_sensor"] #map_modal_content {
  flex-direction: column;
  display: flex;
}

#map_modal[data-modal="wind_sensor"] header #map_modal_title > span {
  color: #00a6ee;
  margin-left: 10px;
  font-size: 15px;
  font-weight: normal;
  display: inline-block;
}

#map_modal[data-modal="poi"] .modal__overlay {
  top: unset;
  z-index: 102;
  background: none;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 0;
}

#map_modal[data-modal="poi"] .modal__container {
  background-color: #0000;
  margin-top: 0;
  margin-bottom: 0;
  padding: 15px 15px 0;
  position: relative;
  bottom: -8px;
}

#map_modal[data-modal="poi"] .modal__container #map_modal_content {
  background-color: #fff;
  border-radius: 4px 0 4px 4px;
  width: calc(100vw - 140px);
  margin-top: -4px;
  margin-bottom: .5rem;
  padding: 15px;
  position: relative;
  box-shadow: 0 0 4px #00000080;
}

#map_modal[data-modal="poi"] .modal__container #header_links {
  background-color: #fffc;
  border-radius: 4px 4px 0 0;
  flex: none;
  padding: 10px 10px 15px;
  box-shadow: 0 0 4px #00000080;
}

#map_modal[data-modal="poi"] .modal__container #header_links button.modal__close {
  background-color: #fff;
  border-radius: 4px;
}

#map_modal[data-modal="poi"] .modal__container #header_links .modal_button_group {
  position: initial;
  flex-direction: row;
  display: flex;
}

#map_modal[data-modal="poi"] .modal__container #header_links .modal_button_group button {
  margin-right: 10px;
}

#map_modal[data-modal="poi"] .modal__container .modal__footer {
  padding-bottom: 15px;
  display: none;
}

.modal__header {
  z-index: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.modal__title {
  color: #00449e;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;
}

.modal__title span {
  color: #000c;
  font-size: .85rem;
}

.modal__close {
  background: none;
  border: 0;
  padding: 5px 10px;
}

.modal__close:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.modal__header .modal__close:before {
  content: "✕";
}

.modal__content {
  color: #000c;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.modal__footer {
  padding-bottom: 30px;
}

.modal__btn {
  color: #000c;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  background-color: #e6e6e6;
  border-style: none;
  border-width: 0;
  border-radius: .25rem;
  margin: 0;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.15;
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  overflow: visible;
  transform: translateZ(0);
}

.modal__btn:focus, .modal__btn:hover {
  transform: scale(1.05);
}

.modal__btn-primary {
  color: #fff;
  background-color: #00449e;
}

.amp_modal {
  flex-flow: wrap;
  align-items: flex-start;
  display: flex;
}

.amp_modal .amp_photo {
  width: 45%;
  padding: 1rem;
}

.amp_modal .amp_photo img {
  max-width: 100%;
  height: auto;
}

.amp_modal .amp_photo p {
  font-size: 9px;
}

.amp_modal .amp_description {
  width: 45%;
  padding: 1rem;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmfadeIn;
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmslideIn;
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmfadeOut;
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: .3s cubic-bezier(0, 0, .2, 1) mmslideOut;
}

.micromodal-slide .modal__container, .micromodal-slide .modal__overlay {
  will-change: transform;
}

#map_modal_content {
  width: 100%;
}

#map_modal_content > section {
  flex: auto;
}

#map_modal_content > aside {
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  line-height: 1.1;
  display: flex;
}

#map_modal_content > aside > * {
  background-color: #f0f0f0;
  border-radius: 4px;
  flex: 1 0 auto;
  max-width: calc(25% - 40px);
  margin: 0 0 20px;
  padding: 15px 20px;
  display: block;
  overflow: hidden;
}

#map_modal_content > aside > .footer_links {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

#map_modal_content > aside h2 {
  text-shadow: 0 1px #fff;
  border-bottom: 1px solid #d6d6d6;
  margin: 0 0 .5em;
  padding-bottom: 5px;
  font-size: 1em;
  line-height: 1.2;
}

#map_modal_content > aside ul {
  padding-left: 20px;
}

#map_modal_content > aside p {
  margin-bottom: 0;
}

#map_modal_content > aside a {
  text-align: center;
  display: block;
}

#map_modal_content > aside a > img {
  max-width: 120px;
  padding: 10px;
}

#map_modal_content > aside .sensor_devent {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#map_modal_content > aside .sensor_devent .devent_pie {
  margin: 10px auto;
}

#map_modal_content > aside .sensor_devent > img {
  float: left;
  height: 120px;
  margin-right: 20px;
  display: block;
}

#map_modal_content > aside .sensor_devent > ul {
  font-size: .8em;
}

#map_modal_content > aside .sensor_devent > p {
  clear: both;
  font-size: .8em;
}

#map_modal_content #aside_map {
  height: 280px;
}

#map_modal_content > aside .tns-nav, #map_modal_content > aside button[data-action="stop"] {
  display: none;
}

#map_modal_content > aside .tns-inner, #map_modal_content > aside .tns-slider, #map_modal_content > aside .tns-item {
  height: 100%;
}

#map_modal_content > aside .tns-item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

#map_modal .modal__header .partners_links {
  flex-direction: row;
  flex: auto;
  justify-content: flex-end;
  display: flex;
}

#map_modal .modal__header .partners_links a {
  margin-right: 10px;
}

#map_modal .modal__header .partners_links img {
  width: auto;
  height: 64px;
}

#map_modal #header_links {
  justify-content: end;
  display: flex;
}

#charts_wrapper .chart_container {
  width: 100%;
  height: 60vh;
  position: relative;
}

#charts_wrapper canvas {
  margin: 16px 0;
}

#charts_wrapper .custom_tooltip {
  color: #fff;
  white-space: nowrap;
  opacity: 1;
  pointer-events: none;
  text-align: left;
  background-color: #000000b3;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: normal;
  transition: all .1s;
  position: absolute;
  bottom: 0;
  transform: translate(-50%);
  font-size: 12px !important;
}

#charts_wrapper .custom_tooltip td {
  flex-flow: row;
  display: flex;
}

#charts_wrapper .custom_tooltip .legend {
  margin-right: 10px;
  border: 1px solid #fff !important;
  width: 10px !important;
  height: 10px !important;
}

.sensor_intro {
  border-left: 3px solid #ff019e;
  margin: 0 0 20px;
  padding-left: 20px;
}

#charts_actions {
  display: flex;
}

#charts_actions > button {
  border: none;
  border-radius: 4px;
  flex: 1 0 auto;
  margin: 0 8px;
  padding: 8px;
}

#charts_actions > button.active, #charts_actions > button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #154194;
}

.radio-container {
  flex-flow: wrap;
  justify-content: space-evenly;
  margin: 20px 0;
  display: flex;
}

.radio-container_item {
  flex-flow: row;
  align-items: baseline;
  display: flex;
}

.radio-container_item input {
  margin: 0 8px;
}

.radio-container_item label:hover {
  cursor: pointer;
}

.modal_button_group {
  cursor: pointer;
  flex-direction: row;
  font-size: .7em;
  display: flex;
  position: absolute;
  top: 15px;
  right: 56px;
}

.modal_button_group button {
  cursor: pointer;
  color: #00a6ee;
  background-color: #fff;
  border: 1px solid #00a6ee;
  border-radius: .25rem;
  padding: 3px 5px;
}

.modal_button_group #view-choice.active {
  color: #fff;
  background-color: #00a6ee;
}

.modal_button_group #modal_favorite.is_favorite {
  background-color: #00a6ee;
}

.modal_button_group #modal_favorite.is_favorite span {
  color: #fff;
}

#news_modal .modal__container {
  max-width: 1320px;
  max-height: calc(100vh - 40px);
}

#news_modal #news_modal_content {
  margin-bottom: 10px;
}

#news_modal #news_modal_content .tns-outer {
  position: relative;
}

#news_modal #news_modal_content .tns-outer > .tns-controls {
  z-index: 99;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
}

#news_modal #news_modal_content .tns-outer > .tns-controls > button {
  color: #1541944d;
  text-align: center;
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 48px;
  line-height: 48px;
}

#news_modal #news_modal_content .tns-nav {
  text-align: center;
}

#news_modal #news_modal_content .tns-nav button {
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 10px 5px 0;
}

#news_modal #news_modal_content .tns-nav button.tns-nav-active {
  background-color: #154194;
}

#news_modal #news_slides .tns-item {
  text-align: center;
}

#news_modal #news_slides .tns-item > div {
  border: 1px solid #15419480;
  display: inline-block;
}

#news_modal #news_slides .tns-item > div img {
  width: auto;
  max-height: 60vh;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.98c109ba.woff2") format("woff2"), url("Montserrat-Regular.be0c644a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Italic.6d837d82.woff2") format("woff2"), url("Montserrat-Italic.b6c5137f.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Bold.09083c5e.woff2") format("woff2"), url("Montserrat-Bold.50ce3831.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-BoldItalic.587b9b97.woff2") format("woff2"), url("Montserrat-BoldItalic.4a0ebc9b.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Light.65416527.woff2") format("woff2"), url("Montserrat-Light.f57b3d66.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-LightItalic.30ad1525.woff2") format("woff2"), url("Montserrat-LightItalic.7cfb11ff.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

body {
  color: #333;
  background-color: #fff;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
}

a:focus, button:focus {
  outline: none;
}

.hidden {
  display: none;
}

.loading {
  text-align: center;
  color: #154194;
  padding: 20px;
  font-weight: 300;
}

#toggle_side_menu {
  z-index: 999;
  color: #154194;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  transition: right .5s ease-out;
  display: flex;
  position: absolute;
  top: 13px;
  right: 13px;
}

#toggle_side_menu i:before {
  content: "";
}

#toggle_side_menu.active {
  right: 313px;
}

#toggle_side_menu.active i:before {
  content: "";
}

#toggle_side_menu:hover {
  cursor: pointer;
}

#toggle_side_menu.active + #side_menu {
  width: 300px;
}

#toggle_side_menu.active ~ #timeline > #timeline_info {
  display: none;
}

#side_menu {
  z-index: 100;
  background-color: #fff;
  width: 0;
  transition: width .5s ease-out;
  position: absolute;
  top: 0;
  bottom: 60px;
  right: 0;
}

#side_menu #side_tabs {
  border-bottom: 1px solid #154194;
  width: 100%;
  display: flex;
}

#side_menu #side_tabs button {
  color: #154194;
  background-color: #fff;
  border: none;
  flex-direction: column;
  flex: 1 0 33.33%;
  justify-content: center;
  align-items: center;
  padding: 16px 12px 0;
  line-height: 32px;
  display: flex;
}

#side_menu #side_tabs button.active {
  border-bottom: 1px solid #154194;
}

#side_menu #side_tabs button span {
  font-size: 12px;
  font-weight: 300;
}

#side_menu #side_tabs button:hover {
  cursor: pointer;
  background-color: #efefef;
}

#side_menu #side_tabs[data-active="layers"] ~ #layers_tab {
  display: flex;
}

#side_menu #side_tabs[data-active="legend"] ~ #legend_tab, #side_menu #side_tabs[data-active="links"] ~ #links_tab {
  display: block;
}

#side_menu .tab_content {
  align-items: flex-start;
  height: calc(100vh - 340px);
  display: none;
  overflow: hidden auto;
}

#side_menu #layers_tab {
  flex-wrap: wrap;
  gap: 0 8px;
  padding: 16px;
}

#side_menu #layers_tab h3 {
  color: #39a9e0;
  flex: 1 0 100%;
  width: 100%;
  margin: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
}

#side_menu #layers_tab h3:not(:first-child) {
  margin-top: 24px;
}

#side_menu #layers_tab .layer_preview {
  text-align: left;
  background-color: #0000;
  border: none;
  width: calc(50% - 8px);
}

#side_menu #layers_tab .layer_preview img {
  object-fit: cover;
  object-position: center;
  border: 2px solid #fff;
  border-radius: 12px;
  max-width: 100%;
  height: 100px;
  padding: 2px;
}

#side_menu #layers_tab .layer_preview span {
  color: #154194;
  font-size: 12px;
  font-weight: normal;
}

#side_menu #layers_tab .layer_preview:hover {
  cursor: pointer;
}

#side_menu #layers_tab .layer_preview.active img {
  border: 2px solid #154194;
}

#side_menu #links_tab {
  padding: 16px;
}

#side_menu #links_tab > div:first-child {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  display: grid;
}

#side_menu #links_tab > div:first-child img {
  max-width: 100%;
}

#side_menu #links_tab > div:last-child {
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  display: flex;
}

#side_menu #links_tab > div:last-child a {
  color: #154194;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  display: flex;
}

#side_menu #links_tab > div:last-child a img {
  width: auto;
  height: 24px;
}

#footer {
  margin-top: 8px;
  padding: 0 20px 10px;
}

#partners a {
  width: 100%;
  display: inline-block;
}

#partners a img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

#footer-partner {
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
}

body.windmorbihan #footer-partner .wla, body.windloireatlantique #footer-partner .wm {
  display: none;
}

#footer-columns {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  display: flex;
}

#footer-columns > a {
  height: 60px;
}

.img_logo img {
  object-fit: contain;
  max-width: 90px;
  height: 60px;
}

#footer hr {
  background-color: #39a9e0;
  border: none;
  width: 240px;
  height: 2px;
  margin: 10px auto;
}

#footer_info {
  margin-bottom: 40px;
  font-size: smaller;
}

#footer_info p {
  text-align: center;
  margin-top: 15px;
}

#footer_info p a {
  color: #000;
  text-decoration: none;
}

#wm_links {
  max-width: 960px;
  margin: 120px auto;
  padding: 20px;
}

#wm_links h1 {
  color: #154194;
}

#wm_links a {
  color: #000;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

#wm_links a img {
  width: 60px;
  height: auto;
  margin-right: 20px;
}

#new_contact {
  border: 1px solid #39a9e0;
  border-radius: 1em;
  width: 600px;
  margin: 120px auto 40px;
  padding: 1em;
}

#new_contact > div + div {
  margin-top: 1em;
}

#new_contact label {
  text-align: right;
  width: 90px;
  display: inline-block;
}

#new_contact input, #new_contact textarea {
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 4px;
  width: 400px;
  padding: 4px 8px;
  font: 1.1em sans-serif;
}

#new_contact input:focus, #new_contact textarea:focus {
  border-color: #000;
}

#new_contact textarea {
  vertical-align: top;
  height: 20em;
}

#new_contact .form_button {
  color: #fff;
  text-transform: uppercase;
  background: #39a9e0;
  border: 4px solid #fff;
  border-radius: 10px;
  margin: 10px auto;
  padding: 1em 3em;
  font-size: 1em;
  font-weight: bold;
  display: block;
  position: relative;
}

#new_contact h3 {
  color: #39a9e0;
  text-transform: uppercase;
  text-align: center;
  font-style: normal;
  font-weight: bold;
}

#new_contact .submit_msg {
  text-align: center;
}

.logo_contact {
  width: 220px;
  height: auto;
  margin-top: -40px;
  margin-left: -170px;
}

@media screen and (width <= 839px) {
  #map_modal[data-modal="poi"] .modal__container {
    filter: drop-shadow(0 0 .3rem #0009);
    width: calc(100vw - 40px);
    padding-bottom: 0;
  }

  #map_modal[data-modal="poi"] .modal__container > #map_modal_content {
    border-radius: 4px;
    width: calc(100vw - 100px);
  }

  .modal_button_group {
    top: unset;
    margin: auto;
    bottom: 1rem;
    right: 15px;
  }

  .modal_button_group button {
    padding: 10px;
  }
}

@media screen and (width >= 769px) {
  #mobile_nav {
    display: none;
  }
}

@media screen and (width <= 768px) {
  #map_bg_menu li > span:last-child {
    display: none;
  }

  #map_container {
    height: calc(100vh - 160px) !important;
    min-height: 525px !important;
  }

  #map_container.wind div#wind_quick_legend {
    display: none;
  }

  div#timeline {
    flex-direction: column;
    min-height: 50px;
  }

  div#timeline #timeline_viewport {
    flex: 0 0 44px;
    order: 1;
    min-height: 44px;
  }

  div#timeline #timeline_viewport .tl_item {
    height: 40px;
  }

  div#timeline #timeline_viewport .tl_item[data-fc]:after {
    height: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  div#timeline #timeline_viewport .tl_item[data-day]:before {
    font-size: 10px;
    bottom: 24px;
  }

  div#timeline #timeline_ts #timeline_cursor {
    height: 44px;
  }

  div#timeline.weather_details #timeline_commands {
    flex: 1 0 auto;
  }

  div#timeline.weather_details #timeline_commands > button {
    width: 32px;
    height: 32px;
  }

  div#timeline.weather_details #timeline_viewport {
    flex: none;
  }

  div#timeline.weather_details #timeline_viewport #weather_details {
    padding-top: 10px;
  }

  div#timeline.weather_details #timeline_ts #timeline_cursor {
    height: 290px;
  }

  div#timeline.weather_details #timeline_viewport #timeline_content {
    padding-left: 0;
  }

  div#timeline.weather_details #modal_favorite > span {
    display: none;
  }

  div#timeline #timeline_ts {
    bottom: 64px;
  }

  div#timeline #timeline_ts #timeline_date {
    font-size: 10px;
    top: 2px;
    left: 10px;
  }

  div#timeline #timeline_commands {
    flex: none;
    order: 2;
    height: 40px;
    margin: 0 32px;
    transition: height .8s;
  }

  div#timeline.no_focus + div#map_layers {
    bottom: 80px;
  }

  div#timeline.no_focus #timeline_commands {
    height: 0;
  }

  div#timeline #weather_details_header {
    z-index: 2;
    background-color: #fff;
    order: -1;
    width: 110px;
    padding-top: 56px;
    position: absolute;
    top: 0;
    left: 0;
  }

  div#timeline #weather_details_header .heading_table > div {
    padding: 0 8px;
    font-size: .8em;
    line-height: 1.1;
  }

  div#timeline #weather_details_header .heading_table > div span.short_label {
    display: inline;
  }

  div#timeline #weather_details_header .heading_table > div span.short_label + span {
    display: none;
  }

  div#timeline #weather_details_header .heading_table > div.poi_title {
    padding: 0 8px;
  }

  #map_modal .radio-container {
    justify-content: start;
  }

  #map_modal .radio-container_item {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  div#map_layers {
    transition: bottom 1s;
    bottom: 120px;
    left: calc(50vw - 186px);
  }

  #map_menu span {
    display: none !important;
  }

  #footer-partner #footer-columns {
    flex-wrap: wrap;
  }

  #footer-partner #footer-columns .img_logo img {
    height: 36px;
  }

  .amp_modal {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .amp_modal .amp_photo {
    width: fit-content;
    padding: 1rem;
  }

  .amp_modal .amp_photo img {
    max-width: 100%;
    height: auto;
  }

  .amp_modal .amp_photo p {
    font-size: 9px;
  }

  .amp_modal .amp_description {
    width: fit-content;
    padding: 1rem;
  }
}

@media screen and (width <= 420px) {
  #map_modal .modal__header, #map_modal #charts_actions, #map_modal aside {
    flex-direction: column;
  }

  #map_modal .radio-container {
    justify-content: start;
  }

  #map_modal .radio-container_item {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  #map_modal #map_modal_content > aside > * {
    max-width: 100%;
  }

  #map_modal .modal__header .partners_links img {
    height: 32px;
    margin-top: 5px;
  }

  #map_wrapper #map_container .leaflet-top.leaflet-right {
    display: none;
  }

  #map_wrapper #map_container .leaflet-bottom.leaflet-right {
    top: 20px;
    right: 10px;
  }

  #map_wrapper #map_container #map_bg_list {
    top: 160px;
  }

  #map_wrapper #map_container #amp_list {
    top: 72px;
  }

  #map_wrapper #map_container #data_info {
    justify-content: center;
    width: 92%;
    display: none;
    bottom: 105px;
    left: 20px;
  }

  #map_wrapper #map_container #data_info > p br {
    display: none;
  }

  #map_wrapper #map_container #data_info > p em {
    padding-left: 5px;
  }

  #news_modal .modal__container {
    max-width: calc(100vw - 20px);
    padding: 10px 10px 0;
  }

  #news_modal #news_modal_content button {
    width: 10px;
    height: 10px;
    margin: 10px 3px 0;
  }

  #news_modal #news_slides .tns-item > div img {
    width: 100%;
    height: auto;
  }

  #partners a {
    text-align: center;
  }

  #partners a img {
    max-width: 300px;
  }
}

#map_container {
  width: 100%;
  height: calc(100vh - 120px);
  min-height: 518px;
  position: relative;
  overflow: hidden;
}

#map_container #map .leaflet-control-container .leaflet-control {
  border: none;
}

#map_container #map .leaflet-control-container .leaflet-control a {
  color: #154194;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#map_container #map .leaflet-control-container .leaflet-control a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#map_container #map .leaflet-control-container .leaflet-control a:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#map_container #map .leaflet-control-container .leaflet-top.leaflet-left {
  top: 3px;
  left: 3px;
}

#map_container #map .leaflet-control-container .leaflet-bottom.leaflet-left {
  bottom: 50%;
  left: 3px;
}

#map_container #map .leaflet-control-container .leaflet-control-attribution, #map_container #map .leaflet-control-container + a {
  display: none;
}

#map_container #map .leaflet-control-container .leaflet-control-locate .leaflet-control-locate-location-arrow:before {
  content: "";
  font-family: "Font Awesome 6 Pro", sans-serif;
  font-size: 20px;
}

#map_menu_list {
  z-index: 99;
  position: absolute;
  top: 10px;
  left: 10px;
}

ul#map_menu {
  height: 46px;
  margin: 0;
  padding: 10px;
  list-style: none;
  position: relative;
}

ul#map_menu li:not(:first-child), ul#map_menu .legend {
  display: none;
}

ul#map_menu.expanded li {
  display: flex;
}

ul#map_menu.expanded .legend {
  color: #fff;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  left: -4px;
}

ul#map_menu.expanded .legend.rt {
  line-height: 20px;
  top: 240px;
}

ul#map_menu.expanded .legend.fc {
  line-height: 18px;
  top: 74px;
}

.layer_menu li {
  color: #fff;
  align-items: center;
  margin-bottom: 0;
  padding: 3px;
  font-size: 11px;
  line-height: 1;
  display: flex;
}

ul#map_menu li:first-child {
  width: 240px;
  padding-top: 0;
  position: absolute;
}

ul#map_menu li:nth-child(2) {
  margin-top: 56px;
}

#map_menu button#toggle_map_menu {
  color: #00a6ee;
  background-color: #fff;
  border-radius: 25px;
  width: 240px;
  height: 52px;
  margin-left: -10px;
  font-size: 22px;
  line-height: 32px;
}

#map_menu button#toggle_map_menu img {
  width: 170px;
  margin: 0 5px;
}

#map_menu #toggle_map_menu > i:nth-child(2), #map_menu.expanded #toggle_map_menu > i:first-child {
  display: none;
}

#map_menu.expanded #toggle_map_menu > i:nth-child(2) {
  display: initial;
}

.layer_menu button {
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: none;
  width: 32px;
  height: 32px;
  padding: 0;
}

.layer_menu button img {
  width: 32px;
  height: auto;
}

#map_menu button {
  margin-right: 10px;
}

#map_bg_menu button {
  margin-left: 10px;
}

#map_bg_menu #legend, #map_bg_menu .legend {
  margin-top: 20px;
}

.layer_menu span {
  display: none;
}

.layer_menu button:hover + span, .layer_menu button.active + span, #legend + span {
  display: initial;
}

.layer_menu button.active, .layer_menu button:hover {
  border-radius: 50%;
  box-shadow: 0 0 0 3px #154194;
}

#map_layers {
  z-index: 99;
  border-radius: 12px;
  column-gap: 4px;
  width: 360px;
  padding: 4px;
  display: flex;
  position: absolute;
  bottom: 75px;
  left: 13px;
}

#map_layers > div > div {
  background-color: #fffc;
  border-radius: 8px;
  flex-wrap: wrap;
  column-gap: 4px;
  padding: 4px;
  display: flex;
}

#map_layers > div:first-child {
  flex: 0 0 136px;
}

#map_layers > div:last-child {
  flex: 0 0 224px;
}

#map_layers > div p {
  text-align: center;
  color: #fff;
  flex: 0 0 100%;
  margin: 0;
  font-size: 12px;
}

#map_layers > div button {
  color: #154194;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  font-size: 18px;
}

#map_layers > div button.active {
  color: #fff;
  background-color: #154194;
}

#map_layers > div button:hover {
  cursor: pointer;
}

#map_layers > div#fc_layers button {
  color: #00a6ee;
}

#map_layers > div#fc_layers button.active {
  color: #fff;
  background-color: #00a6ee;
}

#map_layers > div #map_favorite {
  flex-direction: row;
  align-items: center;
  display: flex;
}

#map_layers > div #map_favorite button {
  color: #fff;
  background-color: #00a6ee;
  font-size: 9px;
}

#map_layers > div .no-favorite {
  background-color: #ff019e !important;
}

#map_bg_menu button {
  order: 2;
}

#map_bg_menu li {
  justify-content: flex-end;
}

#map_controls {
  z-index: 99;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  right: 20px;
  overflow: hidden;
}

#map_controls button, #location_controls button {
  text-align: center;
  color: #00a6ee;
  cursor: pointer;
  background-color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
}

#map_controls button:hover, #location_controls button:hover, #location_controls button.active {
  color: #fff;
  background-color: #00a6ee;
}

#map_controls hr {
  border-top: 1px solid #00a6ee;
  margin: 0 0 -1px;
}

#location_controls {
  z-index: 99;
  border-radius: 4px;
  position: absolute;
  top: 120px;
  right: 20px;
  overflow: hidden;
}

#amp_list {
  z-index: 99;
  position: absolute;
  top: 144px;
  right: 20px;
}

ul#amp_menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

#amp_menu span {
  margin-right: 10px;
}

#amp_menu button {
  order: 2;
}

#amp_menu li {
  justify-content: flex-end;
}

#map_bg_list {
  z-index: 99;
  position: absolute;
  bottom: 14vh;
  right: 20px;
}

ul#map_bg_menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

#timeline {
  z-index: 100;
  background-color: #fff;
  min-height: 60px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#timeline.weather_details div#data_info, #timeline.weather_details div#wind_quick_legend {
  display: none;
}

#timeline.weather_details #timeline_viewport #timeline_content #weather_details {
  display: flex;
}

#timeline.weather_details #timeline_viewport #timeline_steps {
  border-bottom-width: 0;
  width: 6480px;
}

#timeline.weather_details #timeline_viewport #timeline_steps .tl_item {
  width: 5px;
  min-width: 5px;
  height: 50px;
  margin-bottom: -3px;
}

#timeline.weather_details #timeline_viewport #timeline_steps .tl_item[data-rt] {
  border-bottom-width: 0;
}

#timeline.weather_details #timeline_viewport #timeline_steps .tl_item[data-fc]:after {
  text-align: center;
  width: 30px;
  height: 20px;
  font-size: .7em;
  line-height: 20px;
  left: -13px;
}

#timeline.weather_details #timeline_viewport #timeline_steps .tl_item[data-day]:before {
  bottom: 30px;
}

#timeline.weather_details #timeline_commands #weather_details_header {
  display: block;
}

#timeline.weather_details #timeline_ts #timeline_cursor {
  opacity: .33;
  height: 800px;
}

#timeline.weather_details #timeline_info #weather_buttons {
  display: block;
}

#timeline_commands {
  flex-wrap: wrap;
  flex: 0 0 216px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px 0;
  display: flex;
}

#timeline_commands button {
  color: #154194;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 18px;
  display: flex;
}

#timeline_commands button:hover {
  cursor: pointer;
  color: #fff;
  background-color: #154194;
}

#timeline_ts {
  display: inline-block;
}

#timeline_ts #timeline_date {
  width: 80px;
  font-size: 11px;
  display: none;
  position: absolute;
  top: 5px;
  left: 260px;
}

#timeline_ts #timeline_hour {
  color: #fff;
  text-align: center;
  background-color: #154194;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 76px;
  padding: 4px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: calc(50vw - 42px);
}

#timeline_ts #timeline_cursor {
  z-index: 98;
  background-color: #154194;
  width: 2px;
  height: 60px;
  position: absolute;
  top: -2px;
  left: calc(50vw - 1px);
}

#timeline_viewport {
  flex: 0 0 calc(100vw - 256px);
  min-height: 60px;
  position: relative;
  overflow: hidden visible;
}

#timeline_viewport #timeline_steps {
  border-bottom: 2px inset #00a6e7;
  justify-content: space-between;
  align-items: center;
  width: 12960px;
  display: flex;
}

#timeline_viewport #timeline_steps:hover {
  cursor: grab;
}

#timeline_viewport #timeline_steps .tl_item {
  width: 10px;
  min-width: 10px;
  height: 56px;
  margin-bottom: -2px;
  position: relative;
}

#timeline_viewport #timeline_steps .tl_item[data-rt] {
  border-bottom: 2px solid #154194;
}

#timeline_viewport #timeline_steps .tl_item:hover {
  background-color: #e0eef4;
}

#timeline_viewport #timeline_steps .tl_item[data-fc]:after {
  content: attr(data-fc);
  text-align: center;
  width: 60px;
  height: 24px;
  font-size: 13px;
  line-height: 24px;
  position: absolute;
  bottom: 1px;
  left: -24px;
}

#timeline_viewport #timeline_steps .tl_item[data-fc="21h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="22h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="23h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="00h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="01h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="02h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="03h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="04h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="05h"]:after, #timeline_viewport #timeline_steps .tl_item[data-fc="06h"]:after {
  color: #333;
  background-color: #e0eef4;
}

#timeline_viewport #timeline_steps .tl_item[data-day]:before {
  content: attr(data-day);
  width: 64px;
  font-size: 11px;
  position: absolute;
  bottom: 36px;
  left: -6px;
}

img.pointer_direction {
  width: 22px;
}

#timeline_info {
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#wind_quick_legend {
  text-align: center;
  width: 48px;
  padding: 4px;
  font-size: 10px;
  display: none;
  position: absolute;
  top: -198px;
  right: 13px;
}

#wind_quick_legend > div:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#wind_quick_legend > div:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#map_container.wind #wind_quick_legend {
  display: block;
}

#data_info {
  z-index: 99;
  color: #154194;
  background-color: #fffc;
  border-radius: 8px 0 0;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  height: 28px;
  padding: 4px 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

#data_info > div {
  flex: auto;
}

#data_info > div:first-child:last-child {
  flex: 0 0 100%;
}

#data_info > div:not(:first-child) {
  flex: 2 auto;
}

#data_info > div:not(:first-child) > p {
  border-left: 1px solid #154194;
  padding-left: 10px;
}

#data_info > div:first-child:not(:last-child) {
  padding-right: 10px;
}

#data_info p {
  margin: 0;
  font-size: 10px;
  line-height: 1.1;
}

#data_info p em {
  font-size: 10px;
}

#weather_buttons {
  background-color: #fffc;
  border-radius: 8px 0 0;
  padding: 6px 4px;
  display: none;
  position: absolute;
  top: -36px;
  right: 0;
}

#weather_buttons button {
  color: #154194;
  border: none;
  border-radius: 4px;
  min-width: 36px;
  height: 24px;
  margin: 0 4px;
  padding: 6px 10px;
  font-size: 12px;
}

#weather_buttons button span {
  padding-left: 6px;
}

#weather_buttons button:hover {
  cursor: pointer;
}

#weather_buttons button.is_favorite {
  color: #fff;
  background-color: #154194;
}

#map_container.wind_fc #data_info, #map_container.waves_fc #data_info, #map_container.weather_fc #data_info, #map_container.wind #data_info, #map_container.amp #data_info {
  display: flex;
}

#static_canvas, #live_canvas, #forecasts, #measures, #meteogramme_wrapper, #map {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#coast_line {
  z-index: 3;
  background-color: #0f73a9;
  display: none;
}

#map_container.wind_fc.rendered .leaflet-tile-pane > .leaflet-layer {
  z-index: 2 !important;
}

#map_container.wind_fc.rendered #map {
  background-color: #0000;
}

#map_container.waves_fc.rendered .leaflet-tile-pane > .leaflet-layer {
  z-index: 3 !important;
}

#map_container.wind_fc.rendered #coast_line, #map_container.waves_fc.rendered #coast_line {
  background: none;
}

#map_container.waves_fc.rendered #live_canvas, #static_canvas {
  z-index: 2;
}

.fc_colors {
  filter: url("#bgFilter");
}

#map {
  z-index: 6;
  background-color: #0f73a9;
  width: 100%;
  height: 100%;
}

#particles_wrapper {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
}

#waves_wrapper {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

#map .mnt_layer {
  z-index: 201 !important;
}

#forecasts {
  z-index: 2;
}

#measures {
  z-index: 5;
  cursor: pointer;
}

#map_legend {
  color: #154194;
  padding: 20px 10px;
  font-size: 12px;
}

#map_legend #wind_types_legend, #map_legend #sources_legend {
  padding: 0 10px;
}

#map_legend #wind_types_legend > div, #map_legend #sources_legend > div {
  margin-bottom: 10px;
}

#map_legend img {
  width: 16px;
  transform: rotate(-90deg);
}

#map_legend table {
  text-align: center;
  border-collapse: inherit;
  table-layout: fixed;
  color: #154194;
  border-spacing: 10px 0;
  width: 100%;
  margin: auto;
}

#map_legend thead tr th {
  color: #154194;
  text-align: center;
  padding-bottom: 10px;
}

#map_legend tbody tr:first-child td {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

#map_legend tbody tr:last-child td {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

#map_legend tbody tr:nth-child(6), #map_legend tbody tr:nth-child(7), #map_legend tbody tr:nth-child(8), #map_legend tbody tr:nth-child(9) {
  color: #fff;
}

#map_legend table + div {
  justify-content: space-around;
  padding: 10px;
  display: flex;
}

#meteogramme_wrapper {
  z-index: 5;
  display: none;
}

#meteogramme_wrapper.active {
  display: block;
}

#measures .fcc rect {
  fill-opacity: 0;
  cursor: pointer;
}

.wind_details body, .weather_poi body, .webcam_spot body, .temp_sensor body, .location_spot body, .tide_spot body, .amp_spot body, #map_click_details body, .wave_sensor body, .water_temp_sensor body {
  background-color: #0000;
  position: static;
}

.wind_details p, .temp_sensor p, .water_temp_sensor p {
  color: #154194;
  border-radius: 10px;
  margin: 0;
  font-size: 12px;
  display: flex;
  overflow: hidden;
}

.wind_details p > * {
  text-align: center;
  width: 50%;
  padding: 3px 4px;
  display: inline-block;
}

.wind_details p > :first-child {
  padding-left: 6px;
}

.wind_details p > :last-child {
  padding-right: 6px;
}

.wind_sensor {
  fill: #fff;
  color: #fff;
}

.wind_sensor .devent {
  fill: #a4b4cd;
}

.wind_sensor .sensor_name {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  transform: translate(-10px, 26px);
}

.wave_sensor .sensor_name {
  fill: #fff;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  transform: translate(-10px, 36px);
}

.water_temp_sensor .sensor_name {
  fill: #fff;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  transform: translate(36px, 54px);
}

#map_container.wind_fc .wind_details p {
  border: 1px solid #154194;
}

#map_container.wind_fc .wind_sensor {
  stroke: #0000;
}

.temp_sensor p, .water_temp_sensor p {
  background-color: #fff;
  justify-content: center;
  padding: 3px 4px;
}

.water_temp_sensor p {
  color: #00a6e7;
  border: 1px solid #00a6e7;
}

.hide_inactive .weather_poi:not(.poi-active) {
  display: none;
}

.weather_poi .poi_icon {
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px 2px 2px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.poi-active body {
  background-color: #fff9;
  border-radius: 4px;
}

.poi-active body p {
  color: #154194;
  text-align: center;
  width: 100%;
  margin: -10px 0 10px;
  padding: 0 0 10px;
  font-size: 10px;
  line-height: 1;
  display: block;
}

.poi-active body span {
  color: #154194;
}

.weather_poi img {
  width: 36px;
}

.weather_poi p {
  color: #154194;
  text-align: center;
  width: 100%;
  margin: -10px 0 10px;
  padding: 0 0 10px;
  font-size: 10px;
  line-height: 1;
  display: none;
}

.weather_poi:hover p, .weather_poi.active p {
  display: block;
}

.weather_poi:hover body, .weather_poi.active body {
  background-color: #fff9;
  border-radius: 4px;
}

.weather_poi.active .poi_icon, .weather_poi.active p {
  color: #154194;
}

.wave_sensor .waves .capteur_nom {
  display: none;
}

.wave_sensor .swell_data {
  background-color: #0000;
  align-items: center;
  height: 32px;
  text-decoration: none;
  display: flex;
}

.wave_sensor .swell_data .swell_height, .wave_sensor .swell_data .swell_period, .wave_sensor .swell_data .swell_temp {
  color: #154194;
  background-color: #fff;
  padding: 4px;
  font-size: 12px;
  line-height: 12px;
}

.wave_sensor .swell_data .swell_height {
  border-radius: 12px 0 0 12px;
  padding-left: 8px;
}

.wave_sensor .swell_data .swell_temp {
  border-radius: 0 12px 12px 0;
  padding-right: 8px;
}

.wave_sensor .swell_data > div span {
  font-size: 8px;
  display: block;
}

.wave_sensor img {
  width: 32px;
}

.webcam_spot img {
  background-color: #fff;
  border: 1px solid #00a6ee;
  border-radius: 10px;
  width: 28px;
}

.tide_spot img {
  background-color: #00a6ee;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 28px;
}

.amp_spot img {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 10px;
  width: 28px;
}

.amp_zone {
  fill: #e057f780;
  stroke: #e057f7;
}

.location_spot i {
  color: #154194;
  width: 32px;
  height: 32px;
  font-size: 32px;
}

#shom_wrapper {
  -ms-overflow-style: none;
  border: none;
  width: 675px;
  height: 910px;
  overflow: auto;
}

#wm_logo {
  z-index: 99;
  background-color: #fff;
  border-radius: 25px;
  padding: 8px 16px;
  display: block;
  position: absolute;
  top: 13px;
  left: calc(50vw - 76px);
}

#wm_logo img {
  width: 120px;
}

body.windmorbihan #wm_logo .wla {
  display: none;
}

body.windloireatlantique #wm_logo .wla {
  width: 160px;
}

body.windloireatlantique #wm_logo .wm {
  display: none;
}

#map_wrapper .leaflet-popup-content-wrapper {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
}

#map_wrapper .leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 0 !important;
}

#map_wrapper .leaflet-popup-content-wrapper .leaflet-popup-content > p {
  margin: 0;
  padding: 3px 8px;
}

#map_wrapper .leaflet-popup-content-wrapper .leaflet-popup-content .pointer_info {
  width: 85px;
}

#map_wrapper .leaflet-popup-tip {
  width: 2px;
  padding: 0;
  transform: rotate(0);
}

#map_wrapper a.leaflet-popup-close-button {
  color: #fff;
  padding-top: 6px;
  padding-left: 4px;
}

#map_wrapper .leaflet-popup-content-wrapper, #map_wrapper .leaflet-popup-tip {
  box-shadow: none;
}

#weather_title {
  color: #154194;
  background-color: #fffc;
  border-radius: 0 8px 0 0;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  height: 36px;
  display: flex;
  position: absolute;
  top: -36px;
  left: 0;
}

#weather_title h2 {
  margin: 0;
  padding: 0 12px;
  font-size: 12px;
  line-height: 1.1;
}

#timeline_commands #weather_details_header {
  width: 100%;
  display: none;
}

#timeline_commands #weather_details_header .heading_table {
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin-top: 4px;
  display: flex;
}

#timeline_commands #weather_details_header .heading_table > div {
  color: #797979;
  text-align: left;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 16px);
  height: 19.5px;
  margin: 0 12px;
  font-size: 10px;
  line-height: 18px;
  display: flex;
}

#timeline_commands #weather_details_header .heading_table > div button {
  color: #fff;
  text-align: center;
  background-color: #dedede;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 2px;
  font-size: 15px;
  position: relative;
}

#timeline_commands #weather_details_header .heading_table > div button:after {
  content: "+";
  position: absolute;
  top: 0;
  left: 4px;
}

#timeline_commands #weather_details_header .heading_table > div button.active:after {
  content: "-";
}

#timeline_commands #weather_details_header .heading_table > div.poi_title {
  color: #154194;
  padding: 3px 10px;
  font-size: 1.1em;
  font-weight: bold;
}

#timeline_commands #weather_details_header .heading_table > div.poi_title + .table_header_1 {
  height: 4px;
}

#timeline_commands #weather_details_header .heading_table > div .short_label {
  flex: 1 0 auto;
  display: none;
}

#timeline_commands #weather_details_header .heading_table > div .short_label + span {
  flex: 1 0 auto;
}

#timeline_commands #weather_details_header .heading_table > div.details_btn span:nth-child(3):not(:last-child) {
  margin-right: 6px;
}

#timeline_commands #weather_details_header .heading_table > div:not(.details_btn) span:nth-child(3) {
  margin-right: 22px;
}

#timeline_commands #weather_details_header .heading_table .th-border-top {
  border-width: .6px;
  border-color: #797979;
  border-top-style: solid;
}

#timeline_content .icon {
  width: 50px;
  height: 50px;
  margin: .5em;
}

#timeline_content #weather_details {
  cursor: grab;
  width: 100%;
  display: none;
}

#timeline_content #weather_details .content_table {
  flex-direction: column;
  display: flex;
}

#timeline_content #weather_details .content_table > div {
  display: flex;
}

#timeline_content #weather_details .content_table > div > div {
  text-align: center;
  box-sizing: border-box;
  flex: none;
  width: 30px;
  height: 20px;
  padding: 0;
  font-size: .7em;
  line-height: 20px;
}

#timeline_content #weather_details .content_table .details_header, #timeline_content #weather_details .content_table .details_hours {
  opacity: 0;
  height: 0;
}

#timeline_content .day_border {
  border-right: 1px solid #b3b3b3;
}

#timeline_content .day_border_left {
  border-left: 1px solid #b3b3b3;
}

#timeline_content .img-icon {
  width: 24px;
  height: auto;
}

#timeline_content .img-small {
  width: 2em;
  height: auto;
}

#timeline_content .th-border-top {
  border-width: .6px;
  border-color: #797979;
  border-top-style: solid;
}

#timeline_content .button {
  color: #fff;
  text-align: center;
  background-color: #a3a3a3;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 3px -5px 3px 3px;
  padding: 0;
  font-size: 1.2em;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

#timeline_content .button:after {
  content: "+";
  position: absolute;
  top: 3px;
  left: 6px;
}

#timeline_content .button:hover {
  cursor: pointer;
}

#timeline_content .button.active:after {
  content: "-";
}

#timeline_content .txt_smaller {
  font-size: .5em;
}

#timeline_content .night_background {
  background-color: #e5e5e5;
}

#timeline_content .details_source {
  opacity: .3;
  height: 0;
}

#timeline_content .details_source .table_source {
  position: relative;
}

#timeline_content .details_source .table_source[data-source="aro"] + [data-source="arp"]:before {
  content: "< AROME";
  position: absolute;
  top: -40px;
  left: -100%;
}

#timeline_content .details_source .table_source[data-source="aro"] + [data-source="arp"]:after {
  content: "ARPEGE > ";
  position: absolute;
  top: -40px;
  left: 100%;
}
/*# sourceMappingURL=index.8fdbd6b8.css.map */
