.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
}

.modal__container {
  background-color: #fff;
  padding: 30px 30px 0 30px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 120px);
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}

#map_modal[data-modal='wind_sensor'] .modal__container,
#map_modal[data-modal='webcam'] .modal__container {
  width: calc(100vw - 40px);
}
#map_modal[data-modal='amp']  {
 h2{
   color:#7c2fab;
 }
}

#map_modal #map_modal_content > div > .img-icon {
  width: 40px;
}

#map_modal[data-modal='wind_sensor'] {
  #map_modal_content {
    display: flex;
    flex-direction: column;
  }

  header {
    #map_modal_title > span {
      font-weight: normal;
      display: inline-block;
      margin-left: 10px;
      color: #00A6EE;
      font-size: 15px;
    }
  }
}

#map_modal[data-modal='poi'] .modal__overlay {
  position: absolute;
  top: unset;
  width: fit-content;
  bottom: 0;
  margin: 0 auto;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
}

#map_modal[data-modal='poi'] .modal__container {
  position: relative;
  padding: 15px 15px 0 15px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  bottom: -8px;

  #map_modal_content {
    padding: 15px;
    background-color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px 0 4px 4px;
    width: calc(100vw - 140px);
    margin-top: -4px;
    margin-bottom: 0.5rem;
    position: relative;
  }

  #header_links {
    flex: 0 0 auto;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px 4px 0 0;
    padding: 10px 10px 15px 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

    button.modal__close {
      background-color: white;
      border-radius: 4px;
    }

    .modal_button_group {
      display: flex;
      position: initial;
      flex-direction: row;

      button {
        margin-right: 10px;
      }
    }
  }

  .modal__footer {
    padding-bottom: 15px;
    display: none;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
  span{
    font-size: 0.85rem;
    color: rgba(0, 0, 0, .8);
  }
}

.modal__close {
  background: transparent;
  border: 0;
  padding: 5px 10px;
}

.modal__close:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.modal__header .modal__close:before {
  content: "\2715";
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, .8);
}

.modal__footer {
  padding-bottom: 30px;
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, .8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.amp_modal{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  .amp_photo{
    width: 45%;
    padding: 1rem;
    img{
      max-width: 100%;
      height: auto;
    }
    p{
      font-size: 9px;
    }
  }

  .amp_description{
    width: 45%;
    padding: 1rem;
  }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

#map_modal_content {
  width: 100%;
}

#map_modal_content > section {
  flex: auto;
}

#map_modal_content > aside {
  width: 100%;
  line-height: 1.1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#map_modal_content > aside > * {
  display: block;
  background-color: #F0F0F0;
  padding: 15px 20px;
  margin: 0 0 20px;
  border-radius: 4px;
  flex: 1 0 auto;
  max-width: calc(25% - 40px);
  overflow: hidden;

  &.footer_links {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
}

#map_modal_content > aside h2 {
  margin: 0 0 0.5em;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 5px;
  text-shadow: 0 1px 0 #fff;
  font-size: 1em;
  line-height: 1.2;
}

#map_modal_content > aside ul {
  padding-left: 20px;
}

#map_modal_content > aside p {
  margin-bottom: 0;
}

#map_modal_content > aside a {
  display: block;
  text-align: center;
}

#map_modal_content > aside a > img {
  max-width: 120px;
  padding: 10px;
}

#map_modal_content > aside .sensor_devent {
  display: flex;
  flex-direction: column;
  align-items: center;

  .devent_pie {
    margin: 10px auto;
  }

  & > img {
    display: block;
    height: 120px;
    float: left;
    margin-right: 20px;
  }

  & > ul {
    font-size: 0.8em;
  }

  & > p {
    clear: both;
    font-size: 0.8em;
  }
}

#map_modal_content #aside_map {
  height: 280px;
}

#map_modal_content > aside .tns-nav, #map_modal_content > aside button[data-action='stop'] {
  display: none;
}

#map_modal_content > aside {
  .tns-inner, .tns-slider {
    height: 100%;
  }

  .tns-item {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

#map_modal .modal__header .partners_links {
  display: flex;
  flex-direction: row;
  flex: auto;
  justify-content: flex-end;
}

#map_modal .modal__header .partners_links a {
  margin-right: 10px;
}

#map_modal .modal__header .partners_links img {
  height: 64px;
  width: auto;
}

#map_modal #header_links {
  display: flex;
  justify-content: end;
}

#charts_wrapper {
  .chart_container {
    height: 60vh;
    width: 100%;
    position: relative;
  }

  canvas {
    margin: 16px 0;
  }
  .custom_tooltip{
    color: white;
    white-space: nowrap;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transform: translate(-50%, 0);
    transition: all .1s ease;
    position: absolute;
    bottom: 0;
    pointer-events: none;
    text-align: left;
    td{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .legend{
      border: 1px solid white !important;
      margin-right: 10px;
      width: 10px !important;
      height: 10px!important;
    }
  }
}

.sensor_intro {
  margin: 0 0 20px 0;
  padding-left: 20px;
  border-left: 3px solid #ff019e;
}

#charts_actions {
  display: flex;
}

#charts_actions > button {
  flex: 1 0 auto;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin: 0 8px;
}

#charts_actions > button.active, #charts_actions > button:hover {
  background-color: #154194;
  color: white;
  cursor: pointer;
}

.radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 20px 0;

  &_item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;

    input {
      margin: 0 8px;
    }

    label:hover {
      cursor: pointer;
    }
  }
}

.modal_button_group {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: absolute;
  right: 56px;
  top: 15px;
  font-size: 0.7em;

  button {
    border-radius: 0.25rem;
    border: 1px solid #00A6EE;
    cursor: pointer;
    padding: 3px 5px;
    background-color: white;
    color: #00A6EE;
  }

  #view-choice.active {
    background-color: #00A6EE;
    color: white;
  }

  #modal_favorite.is_favorite {
    background-color: #00A6EE;

    span {
      color: white;
    }
  }
}

#news_modal {
  .modal__container {
    max-width: 1320px;
    max-height: calc(100vh - 40px);
  }

  #news_modal_content {
    margin-bottom: 10px;

    .tns-outer {
      position: relative;

      & > .tns-controls {
        position: absolute;
        z-index: 99;
        left: 0;
        top: calc(50% - 24px);
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > button {
          width: 48px;
          height: 48px;
          border: 0;
          border-radius: 50%;
          background-color: transparent;
          color: rgba(21, 65, 148, 0.3);
          font-size: 48px;
          line-height: 48px;
          text-align: center;
        }
      }
    }

    .tns-nav {
      text-align: center;

      button {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: none;
        margin: 10px 5px 0 5px;

        &.tns-nav-active {
          background-color: #154194;
        }
      }
    }
  }

  #news_slides {
    .tns-item {
      text-align: center;

      & > div {
        border: 1px solid rgba(21, 65, 148, 0.5);
        display: inline-block;

        img {
          width: auto;
          max-height: 60vh;
        }
      }
    }
  }
}
