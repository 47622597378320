#weather_title {
  position: absolute;
  top: -36px;
  left: 0;
  height: 36px;
  max-width: 120px;
  background-color: #fffc;
  border-radius: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #154194;

  h2 {
    margin: 0;
    line-height: 1.1;
    padding: 0 12px;
    font-size: 12px;
  }
}

#timeline_commands {
  #weather_details_header {
    width: 100%;
    display: none;

    .heading_table {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-around;
      margin-top: 4px;

      & > div {
        background-color: #FFFFFF;
        color: #797979;
        width: calc(100% - 16px);
        height: 19.5px;
        font-size: 10px;
        text-align: left;
        //font-weight: 300;
        display: flex;
        flex-direction: row;
        align-items: center;
        //justify-content: space-between;
        line-height: 18px;
        margin: 0 12px;

        button {
          color: #fff;
          text-align: center;
          font-size: 15px;
          position: relative;
          background-color: #DEDEDE;
          width: 16px;
          height: 16px;
          padding: 2px;
          border-radius: 50%;

          &:after {
            content: "+";
            position: absolute;
            left: 4px;
            top: 0;
          }

          &.active:after {
            content: '-';
          }
        }

        &.poi_title {
          font-size: 1.1em;
          color: #154194;
          font-weight: bold;
          padding: 3px 10px;

          & + .table_header_1 {
            height: 4px;
          }
        }

        .short_label {
          display: none;
        }

        .short_label {
          flex: 1 0 auto;

          & + span {
            flex: 1 0 auto;
          }
        }

        &.details_btn {
          span:nth-child(3):not(:last-child) {
            margin-right: 6px;
          }
        }

        &:not(.details_btn) {
          span:nth-child(3) {
            margin-right: 22px;
          }
        }
      }

      .th-border-top {
        border-width: 0.6px;
        border-top-style: solid;
        border-color: #797979;
      }
    }
  }
}


#timeline_content {
  .icon {
    width: 50px;
    height: 50px;
    margin: .5em;
  }

  #weather_details {
    display: none;
    width: 100%;
    cursor: grab;

    //.details_hours {
    //  [data-hour='21h'], [data-hour='22h'], [data-hour='23h'], [data-hour='00h'], [data-hour='01h'], [data-hour='02h'],
    //  [data-hour='03h'], [data-hour='04h'], [data-hour='05h'], [data-hour='06h'] {
    //    background-color: #DEDEDE;
    //    display: block;
    //  }
    //}

    .content_table {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;

        & > div {
          width: 30px;
          height: 20px;
          padding: 0;
          font-size: 0.7em;
          line-height: 20px;
          text-align: center;
          box-sizing: border-box;
          flex: 0 0 auto;
        }
      }

      .details_header, .details_hours {
        opacity: 0;
        height: 0;
      }
    }
  }

  .day_border {
    border-right: 1px solid #b3b3b3;
  }

  .day_border_left {
    border-left: 1px solid #b3b3b3;
  }

  .img-icon {
    width: 24px;
    height: auto;
  }

  .img-small {
    width: 2em;
    height: auto;
  }

  .th-border-top {
    border-width: 0.6px;
    border-top-style: solid;
    border-color: #797979;
  }

  .button {
    background-color: #a3a3a3;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2em;
    margin: 3px -5px 3px 3px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    padding: 0;
    position: relative;

    &:after {
      content: '+';
      position: absolute;
      left: 6px;
      top: 3px;
    }

    &:hover {
      cursor: pointer;
    }

    &.active:after {
      content: '-';
    }
  }

  .txt_smaller {
    font-size: 0.5em;
  }

  .night_background {
    background-color: #e5e5e5;
  }

  .table_day, div[data-hour='00h'] {
    //border-left: 2px solid #999;
  }

  //#weather_details.night_hidden {
  //    [data-hour='21h'], [data-hour='22h'], [data-hour='23h'], [data-hour='00h'], [data-hour='01h'], [data-hour='02h'],
  //    [data-hour='03h'], [data-hour='04h'], [data-hour='05h'], [data-hour='06h'] {
  //      display: none;
  //  }
  //  [data-hour='07h'] {
  //    border-left: 2px solid #999;
  //  }
  //

  // Swell values hidden for now
  .details_hs, .details_ds, .details_ps, .details_dsp {
    /*display: none !important;*/
  }

  .details_source {
    opacity: 0.3;
    height: 0;

    .table_source {
      position: relative;
    }

    .table_source[data-source='aro'] + [data-source='arp'] {
      &:before {
        position: absolute;
        top: -40px;
        left: -100%;
        content: "< AROME";
      }

      &:after {
        position: absolute;
        top: -40px;
        left: 100%;
        content: "ARPEGE > ";
      }
    }
  }
}

